import { Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Heading, Input } from '../../components/Common';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { useGetslotListQuery, useSlotDisableMutation } from '../../services/Slot';
import { NoRecordFound, TableLoader } from '../../components/Common/Table';

const Slot = () => {
    const { showMessage } = useMessage();
    const today = new Date().toISOString().substr(0, 10);
    const [selectedDate, setSelectedDate] = useState(today);
    // const [error, setError] = useState(false);

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        const today = new Date().toISOString().substr(0, 10);

        if (selectedDate < today) {
            // setError(true);
            showMessage('error', 'Please select a valid date.');
        } else {
            // setError(false);
            setSelectedDate(selectedDate);
        }
    };

    const { data, error: queryError, isLoading } = useGetslotListQuery({ date: selectedDate });
    const [slots, setSlots] = useState([]);
    const slotList = data?.data;
    const [disableSlot] = useSlotDisableMutation({});

    useEffect(() => {
        if (slotList) {
            setSlots(slotList);
        }
    }, [slotList]);

    const formData = { slotDate: `${selectedDate} ${new Date().toTimeString().split(' ')[0]}` };

    const handleClick = async ({ disableId, disableStatus }) => {
        try {
            setSlots((prev) => {
                return prev.map((item) => {
                    if (item.id === disableId) {
                        return {
                            ...item,
                            disableStatus: disableStatus === 0 ? 1 : 0
                        };
                    }
                    return item;
                });
            });

            let response = await disableSlot({ id: disableId, formData });

            const { status, message } = response?.data || {};

            if (status) {
                if (disableStatus === 0) {
                    showMessage('success', 'Slot enabled successfully');
                } else {
                    showMessage('success', message);
                }
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        }
    };

    return (
        <>
            <Stack mb={1} flexDirection={'row'} gap={2} justifyContent={'space-between'}>
                <Heading head={'Slots'} />
                <Input
                    type='date'
                    value={selectedDate}
                    onChange={handleDateChange}
                    style={{ background: '#fff' }}
                    // error={error}
                    // helperText={error ? 'Please select a valid date.' : ''}
                    inputProps={{ min: today }}
                />
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Day Type</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='4' />) :
                            slots && slots.length > 0 ?
                                (slots.map((ele, i) => {
                                    const { slotTime, slotName, status } = ele;
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{slotName}</TableCell>
                                            <TableCell>{slotTime}</TableCell>
                                            <TableCell>
                                                <Switch defaultChecked
                                                    checked={status === 1}
                                                    onClick={() => handleClick({ disableId: ele.id, disableStatus: ele.status })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }))
                                : <NoRecordFound colspan='4' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default Slot;
