import { Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, Search, TableLoader } from "../../components/Common/Table";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useDebounce from "../../helper/utils";
import { useGetJobListQuery } from "../../services/Job";
import moment from "moment";
import { green, red, yellow, blue, grey, orange, purple, brown, pink, indigo } from '@mui/material/colors';
import { MdMoreVert } from "react-icons/md";

export default function VerifyJob() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchData, setSearchData] = useState('')
    const [reviewDone, setReviewDone] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState({
        open: false, currentComponent: "", data: null, reviewdone: null
    });

    const search = useDebounce(searchData, 500)
    const { data, error, isLoading, refetch } = useGetJobListQuery({ page: page + 1, search, orderStatus: 2, type: state });
    const jobList = data?.data;

    useEffect(() => {
        if (reviewDone) {
            refetch();
            setReviewDone(null);
        }
    }, [reviewDone, refetch]);

    const getStatusChip = (status) => {
        switch (status) {
            case 0:
                return <Chip size="small" label="Upcoming or Placed" style={{ backgroundColor: grey[500], color: 'white' }} />;
            case 1:
                return <Chip size="small" label="Assigned to Technician" style={{ backgroundColor: blue[500], color: 'white' }} />;
            case 2:
                return <Chip size="small" label="On the Way" style={{ backgroundColor: yellow[700], color: 'white' }} />;
            case 3:
                return <Chip size="small" label="Work in Progress" style={{ backgroundColor: orange[500], color: 'white' }} />;
            case 4:
                return <Chip size="small" label="Hold" style={{ backgroundColor: brown[500], color: 'white' }} />;
            case 5:
                return <Chip size="small" label="Completed" style={{ backgroundColor: green[500], color: 'white' }} />;
            case 6:
                return <Chip size="small" label="Client Cancelled" style={{ backgroundColor: red[500], color: 'white' }} />;
            case 7:
                return <Chip size="small" label="Verified" style={{ backgroundColor: purple[500], color: 'white' }} />;
            case 8:
                return <Chip size="small" label="Employee Cancelled" style={{ backgroundColor: pink[500], color: 'white' }} />;
            case 9:
                return <Chip size="small" label="On Going" style={{ backgroundColor: indigo[500], color: 'white' }} />;
            default:
                return '-';
        }
    };
    return (
        <>
            <Stack>
                <Heading head={'Verify Jobs'} />
                <Stack my={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Search setSearch={setSearchData} search={searchData} setPage={setPage} />
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Job ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Category Name</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Technician Name</TableCell>
                            <TableCell>Technician Mobile</TableCell>
                            <TableCell>Job End Date</TableCell>
                            <TableCell>Labour Amount</TableCell>
                            <TableCell>Job Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='12' />) :
                            jobList && jobList.length > 0 ?
                                (jobList?.map((ele, i) => {
                                    const { orderNumber, orderDate, employeeName, employeeMobile, jobCompleteData, serviceDepartmentName, endDate, clientName, phone, orderStatus } = ele;
                                    const formattedDate = moment(orderDate).format('DD-MM-YYYY');
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }} onClick={() => navigate('/view-job', { state: { id: ele?.id, data: ele } })}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{orderNumber}</TableCell>
                                            <TableCell>{formattedDate}</TableCell>
                                            <TableCell>{serviceDepartmentName}</TableCell>
                                            <TableCell>{clientName}</TableCell>
                                            <TableCell>{phone ? phone : '-'}</TableCell>
                                            <TableCell>{employeeName}</TableCell>
                                            <TableCell>{employeeMobile}</TableCell>
                                            <TableCell>{moment(endDate).format('DD-MM-YYYY')}</TableCell>
                                            <TableCell>₹ {parseInt(jobCompleteData?.cashAmount)}</TableCell>
                                            <TableCell>{getStatusChip(orderStatus)}</TableCell>
                                            <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Stack alignItems={'center'} onClick={() => setIsModalOpen({ open: true, currentComponent: "addAdminReview", isEdit: true, data: ele?.id, reviewdone: setReviewDone })}>
                                                    <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan='12' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {jobList && jobList.length > 0 ? <Pagination totalData={data?.totalData || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", data: null, reviewdone: null })}
            />
        </>
    );
}
