import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils'

export const dashboardApis = createApi({
  reducerPath: 'dashboardApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['dashboard'],
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: () => `getDashboardData`,
      providesTags: ['dashboard'],
    }),
  }),
})

export const { useGetDashboardDataQuery } = dashboardApis