import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Grid, Stack, Typography } from '@mui/material';
import { BackButton, Button, ErrorMessage, Heading, Input, Modal, Select, UploadImage } from '../../components/Common';
import { useAddNewJobMutation, useUpdateJobMutation } from '../../services/Job';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { useGetserviceDepartmentDropdownQuery } from '../../services/ServiceDepartment';
import { useGetdropdownServiceQuery } from '../../services/Services';
import { useGetsubServiceDropdownQuery } from '../../services/SubServices';
import { useGetDropdownClientQuery } from '../../services/Client';
import { useSlotListforjobMutation } from '../../services/Slot';
import { useAddressDropdownMutation } from '../../services/Address';

const AddJob = () => {
    const { showMessage } = useMessage();
    const { state } = useLocation();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        userId: '',
        head: '',
        para: '',
        isEdit: false
    });

    const [selectDeptService, setSelectDeptService] = useState('');
    const [selectService, setSelectService] = useState('');
    const [selectSubService, setSelectSubService] = useState('');
    const [selectSlot, setSelectSlot] = useState('');
    const [selectClient, setSelectClient] = useState('');
    const today = new Date().toISOString().substr(0, 10);
    const [selectedDate, setSelectedDate] = useState(today);
    const [selServiceDisabled, setSelServiceDisabled] = useState(true);
    const [addressList, setAddressList] = useState([]);
    const [selectAddress, setSelectAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [clientInput, setClientInput] = useState('');
    const [addressInput, setAddressInput] = useState('');
    const [note, setNote] = useState('');
    const [files, setFiles] = useState([]);


    const currentTime = new Date().toISOString().split('.')[0].replace('T', ' ');

    const { data: serviceDeptList, error: serviceDeptError, isLoading: serviceDeptLoading } = useGetserviceDepartmentDropdownQuery();
    const { data: serviceList, error: serviceError, isLoading: serviceLoading } = useGetdropdownServiceQuery(selectDeptService, { skip: !selectDeptService });
    const { data: subServiceList, error: subServiceError, isLoading: subServiceLoading } = useGetsubServiceDropdownQuery(selectDeptService, { skip: !selectDeptService });
    const { data: clientList, error: clientError, isLoading: clientLoading } = useGetDropdownClientQuery();
    const [slotListForJob, { data: slotData, error: slotError, isLoading: slotLoading }] = useSlotListforjobMutation();
    const [addNewJob] = useAddNewJobMutation();
    const [updateJob] = useUpdateJobMutation();
    const [addressDropdown] = useAddressDropdownMutation();

    useEffect(() => {
        const fetchSlotList = async () => {
            try {
                await slotListForJob({
                    currentTime,
                    selectedDate
                });
            } catch (err) {
                console.error('Failed to fetch slot list:', err);
            }
        };
        fetchSlotList();
    }, [currentTime, selectedDate, slotListForJob]);

    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        const todayDate = new Date().toISOString().substr(0, 10);
        if (dateValue < todayDate) {
            showMessage('error', 'Selected date cannot be less than today.');
        } else {
            setSelectedDate(dateValue);
        }
    };

    const handleServiceChange = (e) => {
        const newValue = e.target.value;
        setSelectDeptService(newValue);
        setSelServiceDisabled(!newValue);
    };

    const handleClientChange = async (event, newValue) => {
        const clientId = newValue ? newValue.id : '';
        setSelectClient(clientId);
        if (clientId) {
            const { data } = await addressDropdown({ userId: clientId });
            setAddressList(data?.data);
        }
    };

    const handleAddressChange = (event, newValue) => {
        setSelectAddress(newValue ? newValue.id : '');
    };

    const validateForm = () => {
        const newErrors = {};
        if (!selectDeptService) newErrors.selectDeptService = 'Service Category is required';
        if (!selectClient) newErrors.selectClient = 'Client is required';
        if (!selectAddress) newErrors.address = 'Client Address is required';
        if (!selectService) newErrors.selectService = 'Service is required';
        if (!selectSubService) newErrors.selectSubService = 'Sub-Service is required';
        if (!selectedDate) newErrors.selectedDate = 'Schedule Date is required';
        if (!selectSlot) newErrors.selectSlot = 'Schedule Time is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmit = async () => {
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append('addressId', selectAddress);
        formData.append('userId', selectClient);
        formData.append('orderDate', selectedDate);
        formData.append('orderSlot', selectSlot);
        formData.append('subServiceId', selectSubService);
        formData.append('serviceId', selectService);
        formData.append('serviceDepartmentId', selectDeptService);
        formData.append('note', note);
        formData.append('attachment', files);

        try {
            const response = state
                ? await updateJob({ formData, id: state.id })
                : await addNewJob(formData);

            const { status, message } = response?.data || {};
            if (status) {
                navigate('/jobs');
                showMessage('success', message);
            } else {
                showMessage('error', response?.error?.data?.message);
            }
        } catch (error) {
            console.error('Failed to save the job:', error);
        }
    };


    const handleAddressAdded = async () => {
        if (selectClient) {
            const { data } = await addressDropdown({ userId: selectClient });
            setAddressList(data?.data);
        }
    };

    return (
        <>
            <Stack flexDirection={'row'} gap={1} mb={1}>
                <BackButton />
                <Heading head='Add Job' />
            </Stack>
            <Box className='card_container'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Stack mb={'2px'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500}>Select Clients</Typography>
                            <Typography
                                fontSize={{ xs: '12px', sm: '13px' }}
                                sx={{ color: 'var(--primary)', textDecoration: 'underline', cursor: 'pointer' }}
                                fontWeight={500}
                                onClick={() => setIsModalOpen({ open: true, currentComponent: "addClient", isEdit: false })}>
                                + Add Clients
                            </Typography>
                        </Stack>
                        <Autocomplete
                            options={clientLoading ? [] : clientList?.data}
                            getOptionLabel={(option) => option.name}
                            value={clientList?.data?.find(client => client.id === selectClient) || null}
                            onChange={handleClientChange}
                            inputValue={clientInput}
                            onInputChange={(event, newInputValue) => setClientInput(newInputValue)}
                            renderInput={(params) => <Input {...params} />}
                        />
                        {errors.selectClient && <ErrorMessage message={errors.selectClient} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack mb={'2px'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500}>Select Address</Typography>
                            <Typography
                                fontSize={{ xs: '12px', sm: '13px' }}
                                sx={{ color: selectClient ? 'var(--primary)' : '#c2c2c2', textDecoration: 'underline', cursor: selectClient ? 'pointer' : 'not-allowed' }}
                                fontWeight={500}
                                onClick={() => selectClient && setIsModalOpen({ open: true, currentComponent: "addAddress", isEdit: false, userId: selectClient })}>
                                + Add Address
                            </Typography>
                        </Stack>
                        <Autocomplete
                            disabled={!selectClient}
                            options={addressList || []}
                            getOptionLabel={(option) => option.name}
                            value={addressList?.find(address => address.id === selectAddress) || null}
                            onChange={handleAddressChange}
                            inputValue={addressInput}
                            onInputChange={(event, newInputValue) => setAddressInput(newInputValue)}
                            renderInput={(params) => <Input {...params} />}
                        />
                        {errors.address && <ErrorMessage message={errors.address} />}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select
                            selectList={serviceDeptLoading ? [] : serviceDeptList?.data}
                            labelinput='Select Service Category'
                            value={selectDeptService}
                            onChange={handleServiceChange}
                        />
                        {errors.selectDeptService && <ErrorMessage message={errors.selectDeptService} />}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select
                            disabled={selServiceDisabled}
                            selectList={subServiceLoading ? [] : subServiceList?.data}
                            labelinput='Select Sub-Service'
                            value={selectSubService}
                            onChange={(e) => setSelectSubService(e.target.value)}
                        />
                        {errors.selectSubService && <ErrorMessage message={errors.selectSubService} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500}>Select Service</Typography>
                        <Autocomplete
                            disabled={selServiceDisabled}
                            options={serviceLoading ? [] : serviceList?.data}
                            getOptionLabel={(option) => option.serviceName}
                            value={serviceList?.data?.find(service => service.id === selectService) || null}
                            onChange={(e, newValue) => setSelectService(newValue ? newValue.id : '')}
                            renderInput={(params) => <Input {...params} />}
                        />
                        {errors.selectService && <ErrorMessage message={errors.selectService} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            labelinput='Schedule Date'
                            type='date'
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                        {errors.selectedDate && <ErrorMessage message={errors.selectedDate} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            labelinput='Schedule Time'
                            selectList={slotLoading ? [] : slotData?.data}
                            value={selectSlot}
                            onChange={(e) => setSelectSlot(e.target.value)}
                        />
                        {errors.selectSlot && <ErrorMessage message={errors.selectSlot} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>
                            Note Image
                        </Typography>
                        <UploadImage files={files} setFiles={setFiles} />

                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            labelinput='Note'
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} gap={1}>
                            <Button buttonName='save' onClick={handleFormSubmit} />
                            <Button buttonName='cancel' color='white' onClick={() => navigate('/jobs')} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                modalOpen={isModalOpen}
                onAddressAdded={handleAddressAdded}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, userId: '' })}
            />
        </>
    );
};

export default AddJob;
