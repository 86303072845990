import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils'

export const locationApis = createApi({
    reducerPath: 'locationApis',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['location'],
    endpoints: (builder) => ({
        getStateList: builder.query({
            query: () => `getState`,
            // providesTags: ['location'],
        }),
        getCityList: builder.query({
            query: (id) => `getCity/${id}`,
            // providesTags: ['location'],
        }),

    }),
})

export const { useGetCityListQuery, useGetStateListQuery } = locationApis