import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { BackButton, Button, Heading, Input, UploadImage } from '../../components/Common';
import { getAllData } from '../../helper/indexDB';
import { useGetAdminDetailQuery } from '../../services/Admin';

const Profile = () => {
    const [edit, setEdit] = useState(false);
    const [user, setUser] = useState('');
    const [profileData, setProfileData] = useState({
        email: '',
        phone: '',
        state: '',
        city: '',
        latitude: '',
        longitude: '',
        name: '',
    });

    const { data, error, isLoading } = useGetAdminDetailQuery(user, { skip: !user });

    useEffect(() => {
        const fetchData = async () => {
            const storedData = await getAllData();
            setUser(storedData?.[0]?.value.id);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            setProfileData({
                email: data?.data?.email || '-',
                phone: data?.data?.mobile || '-',
                state: data?.data?.stateName || '-',
                city: data?.data?.cityName || '-',
                latitude: data?.data?.latitude || '-',
                longitude: data?.data?.longitude || '-',
                name: data?.data?.name,
            });
        }
    }, [data]);

    const handleClick = () => setEdit((show) => !show);

    const handleChange = (e) => {
        setProfileData({
            ...profileData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <>
            <Stack flexDirection={'row'} justifyContent={'flex-start'} gap={1} alignItems={'center'} mb={2}>
                <BackButton />
                <Heading head='Profile' />
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box className='card_container'>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container sx={{ width: { xs: '100%', sm: '100%' } }}>
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                        {/* <UploadImage /> */}
                                    </Grid>
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={1}>
                                        <Typography fontSize={'18px'} fontWeight={500}>{profileData.name}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Input
                                            labelinput='Email'
                                            variant='standard'
                                            name='email'
                                            maxLength={50}
                                            disabled={true}
                                            value={profileData.email}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            labelinput='Phone'
                                            variant='standard'
                                            name='phone'
                                            maxLength={10}
                                            disabled={!edit}
                                            value={profileData.phone}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            labelinput='State'
                                            variant='standard'
                                            name='state'
                                            maxLength={20}
                                            disabled={!edit}
                                            value={profileData.state}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            labelinput='City'
                                            variant='standard'
                                            name='city'
                                            maxLength={20}
                                            disabled={!edit}
                                            value={profileData.city}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            labelinput='Latitude'
                                            variant='standard'
                                            name='latitude'
                                            maxLength={6}
                                            disabled={!edit}
                                            value={profileData.latitude}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            labelinput='Longitude'
                                            variant='standard'
                                            name='longitude'
                                            maxLength={6}
                                            disabled={!edit}
                                            value={profileData.longitude}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Stack gap={1} flexDirection={'row'} justifyContent={'flex-end'}>
                                    {edit ? (
                                        <>
                                            <Button buttonName='Cancel' onClick={handleClick} />
                                            <Button buttonName='Save' />
                                        </>
                                    ) : (
                                        <Button buttonName='Edit' onClick={handleClick} />
                                    )}
                                </Stack>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Profile;
