import { MenuItem, Select, Typography } from '@mui/material'
import React from 'react'

const AddressDropDown = ({ onChange, value, selectList, disabled }) => {

    return (
        <div>
            {/* <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Select Address</Typography> */}
            <Select
                value={value}
                onChange={onChange}
                // displayEmpty
                fullWidth
                name='city'
                disabled={disabled}
            >
                {selectList?.map((data, i) => {
                    return (
                        <MenuItem value={data?.id} key={i}>{data?.name}</MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

export default AddressDropDown
