import React, { useEffect, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import { Button, ErrorMessage, FileUpload, Select } from '../../components/Common'
import { useAddNewBannerMutation, useUpdateBannerMutation } from '../../services/banner'
import { useMessage } from '../../components/Common/Message/MessageContext'
import { bannerTypeList } from '../../helper'
import { useGetserviceDepartmentDropdownQuery } from '../../services/ServiceDepartment'

const AddBanner = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();
    const [files, setFiles] = useState('');
    const [bannerType, setBannerType] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageType, setErrorMessageType] = useState('');
    const [errorMessageService, setErrorMessageService] = useState('');
    const [addBanner] = useAddNewBannerMutation();
    const [updateBanner] = useUpdateBannerMutation();
    const handleBannerTypeChange = (e) => setBannerType(e.target.value);
    const [selectService, setSelectService] = useState(null);
    const handleChange = (e) => {
        setSelectService(e.target.value);
    };

    const { data, error, isLoading } = useGetserviceDepartmentDropdownQuery();
    const serviceList = data?.data;

    const handleSubmit = async () => {
        if (!files) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        if (!bannerType) {
            setErrorMessageType('Please select a Banner Type.');
            return;
        }

        if (bannerType === '2' && !selectService) {
            setErrorMessageService('Please select a Service Department');
            return;
        }

        const formData = new FormData();
        formData.append('image', files);
        formData.append('type', bannerType);
        formData.append('serviceDepartmentId', selectService);

        try {
            let response;
            if (modalOpen.isEdit) {
                response = await updateBanner({ formData, id: modalOpen.id });
            } else {
                response = await addBanner(formData);
            }

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
            handleClose();
            setFiles('');
            setErrorMessage('');
        }
    };

    useEffect(() => {
        if (modalOpen.data) {
            setFiles(modalOpen.data.image);
            setBannerType(modalOpen.data.type.toString());
            setSelectService(modalOpen.data.serviceDepartmentId);
        }
    }, [modalOpen.data]);
    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : "Add"} Banner
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Select selectList={bannerTypeList} labelinput='Banner Type' name="bannerType" value={bannerType} onChange={handleBannerTypeChange} />
                        <ErrorMessage message={errorMessageType} />
                    </Grid>
                    {bannerType && bannerType === '2' &&
                        <Grid item xs={12}>
                            <Select
                                selectList={isLoading ? [] : serviceList}
                                labelinput='Service Category'
                                name='serviceCategory'
                                value={selectService}
                                onChange={handleChange}
                            />
                            <ErrorMessage message={errorMessageService} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FileUpload setFiles={setFiles} files={files} setErrorMessage={setErrorMessage} />
                        <ErrorMessage message={errorMessage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    );
};

export default AddBanner;