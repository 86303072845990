import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("ROLE");

    if (token) {
        if (role === "1") {
            return <Navigate to="/dashboard" />;
        } else if (role === "2") {
            return <Navigate to="/user" />;
        }
    }

    return children;
};

export default PublicRoute;
