import { useEffect, useRef, useState } from "react";
import { Box, Stack, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Divider, Typography, Autocomplete } from "@mui/material";
import { Button, Heading, Input, Select } from "../../components/Common";
import { useGetserviceDepartmentDropdownQuery } from "../../services/ServiceDepartment";
import {
    useCustomerReportMutation, useTechnicianReportMutation, useServiceReportMutation, useAllJobReportMutation, useSingleJobReportMutation,
    useHoldJobReportMutation, useCancelJobReportMutation, useCustomerJobReportMutation, useAllJobPaymentReportMutation, usePendingPaymentReportMutation,
    useRecievedPaymentReportMutation, useRattingReportMutation, useJobNumberDropdownMutation, useGetEmployeeDropdownQuery,
    useFeedbackReportMutation
} from "../../services/Report";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { useGetDropdownClientQuery } from "../../services/Client";
import { useGetCityListQuery, useGetStateListQuery } from "../../services/Location";
import { useGetAdminDetailQuery } from "../../services/Admin";
import { getAllData } from "../../helper/indexDB";
import { useMessage } from "../../components/Common/Message/MessageContext";
import { CSVLink } from "react-csv";


export default function Reports() {
    const { showMessage } = useMessage();
    const csvLinkRef = useRef();

    const [selectDeptService, setSelectDeptService] = useState('');
    const [report, setReport] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectClient, setSelectClient] = useState('');
    const [clientInput, setClientInput] = useState('');
    const [selectTechnician, setSelectTechnician] = useState('');
    const [technicianInput, setTechnicianInput] = useState('');
    const [jobIdList, setJobIdList] = useState([]);
    const [jobId, setJobId] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [user, setUser] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [isReportGenerated, setIsReportGenerated] = useState(false);

    const startDateStr = moment(startDate).format('YYYY-MM-DD');
    const endDateStr = moment(endDate).format('YYYY-MM-DD');

    const [jobDropdown, { isLoading: jobLoading }] = useJobNumberDropdownMutation();
    const { data: clientList, error: clientError, isLoading: clientLoading } = useGetDropdownClientQuery();
    const { data: employeeList, error: employeeError, isLoading: employeeLoading } = useGetEmployeeDropdownQuery();
    const { data: serviceDeptList, error: serviceDeptError, isLoading: serviceDeptLoading } = useGetserviceDepartmentDropdownQuery(undefined, { skip: report !== 'services' });
    const serviceDept = serviceDeptList?.data;
    const { data: stateLst, error: stateError, isLoading: stateLoading } = useGetStateListQuery();
    const { data: cityLst, error: cityError, isLoading: cityLoading } = useGetCityListQuery(state, { skip: !state });
    const cityList = cityLst?.data;
    const stateList = stateLst?.data;
    const { data, error, isLoading } = useGetAdminDetailQuery(user, { skip: !user });
    const userType = localStorage.getItem("ROLE");

    const [month, setMonth] = useState("");

    const months = [
        { id: '1', name: "January" },
        { id: '2', name: "February" },
        { id: '3', name: "March" },
        { id: '4', name: "April" },
        { id: '5', name: "May" },
        { id: '6', name: "June" },
        { id: '7', name: "July" },
        { id: '8', name: "August" },
        { id: '9', name: "September" },
        { id: '10', name: "October" },
        { id: '11', name: "November" },
        { id: '12', name: "December" },
    ];

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const storedData = await getAllData();
            setUser(storedData?.[0]?.value.id);
        };

        if (userType === '2') {
            setCity(data?.data?.city)
        }

        fetchData();
    }, [data]);

    const [customerReport] = useCustomerReportMutation();
    const [technicianReport] = useTechnicianReportMutation();
    const [serviceReport] = useServiceReportMutation();
    const [allJobReport] = useAllJobReportMutation();
    const [singleJobReport] = useSingleJobReportMutation();
    const [holdJobReport] = useHoldJobReportMutation();
    const [cancelJobReport] = useCancelJobReportMutation();
    const [customerJobReport] = useCustomerJobReportMutation();
    const [allJobPaymentReport] = useAllJobPaymentReportMutation();
    const [pendingPaymentReport] = usePendingPaymentReportMutation();
    const [recievedPaymentReport] = useRecievedPaymentReportMutation();
    const [rattingReport] = useRattingReportMutation();
    const [feedbackReport] = useFeedbackReportMutation();

    const handleServiceFilter = (serviceFilter) => setSelectDeptService(serviceFilter);
    const handleStateChange = (e) => setState(e.target.value);
    const handleCityChange = (e) => setCity(e.target.value)
    const handleChange = (e) => setReport(e.target.value);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleClientChange = async (event, newValue) => {
        const clientId = newValue ? newValue.id : '';
        setSelectClient(clientId);
        if (clientId) {
            const { data } = await jobDropdown({ userId: clientId });
            setJobIdList(data?.data);
        }
    };

    const handleEmployeeChange = async (event, newValue) => {
        const clientId = newValue ? newValue.id : '';
        setSelectTechnician(clientId);
    };

    const handleJobIdChange = async (event, newValue) => {
        setJobId(newValue)
    };


    const handleExportReport = async () => {
        let response;
        try {
            if (report === 'customer') {
                response = await customerReport();
            } else if (report === 'technician') {
                response = await technicianReport();
            } else if (report === 'services') {
                response = await serviceReport({ sId: selectDeptService });
            } else if (report === 'allJob') {
                response = await allJobReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'singleJob') {
                response = await singleJobReport({ clientId: selectClient, orderNumber: jobId });
            } else if (report === 'hold') {
                response = await holdJobReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'cancel') {
                response = await cancelJobReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'customerJob') {
                response = await customerJobReport({ cId: selectClient, month: month });
            } else if (report === 'allJobPayment') {
                response = await allJobPaymentReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'pendingPayment') {
                response = await pendingPaymentReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'recievedPayment') {
                response = await recievedPaymentReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'rating') {
                response = await rattingReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            } else if (report === 'feedback') {
                response = await feedbackReport({ fDate: startDateStr, tDate: endDateStr, cId: city, tId: selectTechnician });
            }

            if (!response || !response.data) {
                throw new Error('No data found for the selected report.');
            }

            setCsvData(response?.data?.data);
            setIsReportGenerated(true);
        } catch (error) {
            showMessage('error', error.message);
        } finally {
            resetForm();
        }
    };

    useEffect(() => {
        if (csvData.length > 0 && isReportGenerated) {
            setTimeout(() => {
                csvLinkRef.current.link.click();
                setIsReportGenerated(false);
                setReport(''); // Reset the report type
            }, 100); // Delay to ensure the CSV download is triggered
        }
    }, [csvData, isReportGenerated]);

    const resetForm = () => {
        // setReport('')
        setSelectDeptService('');
        setStartDate(null);
        setEndDate(null);
        setSelectClient('');
        setClientInput('');
        setSelectTechnician('');
        setTechnicianInput('');
        setJobIdList([]);
        setJobId('');
        setCity('');
        setState('');
    };

    const reportTypes = ['allJob', 'hold', 'cancel', 'allJobPayment', 'pendingPayment', 'recievedPayment', 'rating', 'feedback'];


    return (
        <>
            <Stack mb={1}>
                <Heading head={'Reports'} />
            </Stack>
            <Box className='card_container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Select Report</FormLabel>
                        <FormControl>
                            <RadioGroup row sx={{ mt: 1, justifyContent: 'flex-start' }} value={report} onChange={handleChange}>
                                {userType === '2' ? '' : <FormControlLabel value="customer" control={<Radio />} label="Customer" />}
                                {userType === '2' ? '' : <FormControlLabel value="technician" control={<Radio />} label="Technician" />}
                                {userType === '2' ? '' : <FormControlLabel value="services" control={<Radio />} label="Services" />}
                                <FormControlLabel value="allJob" control={<Radio />} label="All Job" />
                                <FormControlLabel value="singleJob" control={<Radio />} label="Single Job" />
                                <FormControlLabel value="hold" control={<Radio />} label="Hold" />
                                <FormControlLabel value="cancel" control={<Radio />} label="Cancel" />
                                <FormControlLabel value="customerJob" control={<Radio />} label="Customer Job" />
                                <FormControlLabel value="allJobPayment" control={<Radio />} label="All Job Payment" />
                                <FormControlLabel value="pendingPayment" control={<Radio />} label="Pending Payment" />
                                <FormControlLabel value="recievedPayment" control={<Radio />} label="Recieved Payment" />
                                <FormControlLabel value="rating" control={<Radio />} label="Rating" />
                                <FormControlLabel value="feedback" control={<Radio />} label="Feedback" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {report !== '' &&
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    }
                    {report === 'services' &&
                        <Grid item xs={12}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Select Service Department</Typography>
                            {serviceDeptLoading ? '' :
                                <Stack flexDirection={'row'} flexWrap={'wrap'} justifyContent={'flex-start'} gap={'5px'} mt={1}>
                                    <div onClick={() => handleServiceFilter('')}
                                        className={`${selectDeptService === '' ? 'filter_active_button' : ''} filter_button`}
                                    >All</div>
                                    {serviceDept?.map((ele, i) => {
                                        return <div key={i} onClick={() => handleServiceFilter(ele.id)}
                                            className={`${selectDeptService === ele.id ? 'filter_active_button' : ''} filter_button`}
                                        >{ele.serviceName}</div>
                                    })}
                                </Stack>
                            }
                        </Grid>
                    }
                    {reportTypes.includes(report) &&
                        <Grid item xs={6}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Select Date Range</Typography>
                            <Stack>
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    isClearable
                                    placeholderText='Start Date - End Date'
                                />
                            </Stack>
                        </Grid>
                    }
                    {report === 'customerJob' &&
                        <Grid item xs={6}>
                            <Select selectList={months} labelinput='Select Month' value={month} onChange={handleMonthChange} />

                            {/* <Input labelinput='Select Month' type='month' onChange={handleMonthChange} /> */}
                        </Grid>
                    }
                    {(report === 'singleJob' || report === 'customerJob') &&
                        <Grid item xs={6}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Select Client</Typography>
                            <Autocomplete
                                options={clientLoading ? [] : clientList?.data}
                                getOptionLabel={(option) => option.name}
                                value={clientList?.data?.find(client => client.id === selectClient) || null}
                                onChange={handleClientChange}
                                inputValue={clientInput}
                                onInputChange={(event, newInputValue) => setClientInput(newInputValue)}
                                renderInput={(params) => <Input {...params} />}
                            />
                        </Grid>
                    }
                    {reportTypes.includes(report) &&
                        <Grid item xs={6}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Select Employee</Typography>
                            <Autocomplete
                                options={employeeLoading ? [] : employeeList?.data}
                                getOptionLabel={(option) => option.name}
                                value={employeeList?.data?.find(client => client.id === selectTechnician) || null}
                                onChange={handleEmployeeChange}
                                inputValue={technicianInput}
                                onInputChange={(event, newInputValue) => setTechnicianInput(newInputValue)}
                                renderInput={(params) => <Input {...params} />}
                            />
                        </Grid>
                    }
                    {report === 'singleJob' &&
                        <Grid item xs={6}>
                            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Select Job ID</Typography>
                            <Autocomplete
                                options={jobLoading ? [] : jobIdList}
                                getOptionLabel={(option) => option}
                                value={jobIdList.find(data => data === jobId) || null}
                                onChange={handleJobIdChange}
                                inputValue={jobId}
                                onInputChange={(event, newInputValue) => setJobId(newInputValue)}
                                renderInput={(params) => <Input {...params} />}
                            />
                        </Grid>
                    }
                    {userType === '2' ? '' : reportTypes.includes(report) &&
                        <Grid item xs={6}>
                            <Select selectList={stateLoading ? [] : stateList} labelinput='State' value={state} onChange={handleStateChange} />
                        </Grid>
                    }
                    {userType === '2' ? '' : reportTypes.includes(report) &&
                        <Grid item xs={6}>
                            <Select selectList={cityLoading ? [] : cityList} labelinput='City' value={city} onChange={handleCityChange} />
                        </Grid>
                    }

                    {report !== '' &&
                        <Grid item xs={12}>
                            <CSVLink
                                data={csvData}
                                filename={`${report}-report.csv`}
                                className="hidden"
                                ref={csvLinkRef} // Assign ref to CSVLink
                                target="_blank"
                            />
                            <Button buttonName='Export Report' onClick={handleExportReport} />
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    );
}
