import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const servicesApis = createApi({
  reducerPath: 'servicesApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Services'],
  endpoints: (builder) => ({
    getServicesList: builder.query({
      query: ({ page, search, selectDeptService }) => `serviceList?page=${page}&search=${search}&serviceDepartment=${selectDeptService}`,
      providesTags: ['Services'],
    }),
    getServiceDetail: builder.query({
      query: ({ id }) => `serviceDetail/${id}`,
      providesTags: ['Services'],
    }),
    getdropdownService: builder.query({
      query: (id) => `dropdownService/${id}`,
      providesTags: ['ServiceDept'],
    }),
    addNewServices: builder.mutation({
      query: (body) => {
        return { url: `addService`, body, method: "POST" }
      },
      invalidatesTags: ['Services'],
    }),
    updateServices: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `updateService/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Services'],
    }),
    deleteServices: builder.mutation({
      query: (id) => {
        return { url: `deleteService/${id}`, method: "DELETE" }
      },
      invalidatesTags: ['Services'],
    }),
    statusChangeService: builder.mutation({
      query: (body) => {
        const { id } = body;
        return { url: `statusChangeService/${id}`, method: "PUT" }
      },
      invalidatesTags: ['Services'],
    }),
  }),
})

export const { useGetServicesListQuery, useGetdropdownServiceQuery, useAddNewServicesMutation, useGetServiceDetailQuery,
  useDeleteServicesMutation, useUpdateServicesMutation, useStatusChangeServiceMutation } = servicesApis