import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { BackButton, Heading } from '../../components/Common';
import { useGetServiceDetailQuery } from '../../services/Services';
import { useLocation } from 'react-router-dom';


const ServiceDetails = () => {
    const { state } = useLocation();
    const [id, setId] = useState();

    const { data, error, isLoading } = useGetServiceDetailQuery({ id: id });
    const serviceDetails = data?.data;

    useEffect(() => {
        if (state) {
            setId(state?.id)
        }
    }, [state]);

    return (
        <>
            <Stack flexDirection={'row'} gap={1} mb={1}>
                <BackButton />
                <Heading head={'Service Details'} />
            </Stack>
            <Box className='card_container'>
                <Grid container spacing={{ xs: 2, sm: 3 }}>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} gap={{ xs: 2, sm: 3 }} alignItems={'center'} flexWrap={'wrap'}>
                            <img src={serviceDetails?.image} alt='' width={120} height={120} />
                            <Stack flexDirection={'row'} gap={{ xs: 2, sm: 3, md: 4 }} flexWrap={'wrap'}>
                                <Stack flexDirection={'column'} gap={'10px'} flexWrap={'wrap'}>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <Typography fontWeight={'500'} color={'var(--text)'}>Service Category :</Typography>
                                        <Typography fontWeight={'500'}>{serviceDetails?.serviceDepartmentId?.serviceName}</Typography>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <Typography fontWeight={'500'} color={'var(--text)'}>Sub Service :</Typography>
                                        <Typography fontWeight={'500'}>{serviceDetails?.subServiceId?.subServiceName}</Typography>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <Typography fontWeight={'500'} color={'var(--text)'}>Service Name :</Typography>
                                        <Typography fontWeight={'500'}>{serviceDetails?.serviceName}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack flexDirection={'column'} gap={'10px'} flexWrap={'wrap'}>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <Typography fontWeight={'500'} color={'var(--text)'}>Most Book Service :</Typography>
                                        <Typography fontWeight={'500'}>{serviceDetails?.mostBooked === 1 ? 'Yes' : 'No'}</Typography>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <Typography fontWeight={'500'} color={'var(--text)'}>Service Price :</Typography>
                                        <Typography fontWeight={'500'}>₹ {serviceDetails?.servicePrice}</Typography>
                                    </Stack>
                                    <Stack flexDirection={'row'} gap={1}>
                                        <Typography fontWeight={'500'} color={'var(--text)'}>Offer :</Typography>
                                        <Typography fontWeight={'500'}>₹ {serviceDetails?.offer}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack gap={1}>
                            <Typography fontWeight={'500'} color={'var(--text)'}>Service Description :</Typography>
                            <Typography fontWeight={'500'} dangerouslySetInnerHTML={{ __html: serviceDetails?.serviceDescription }}></Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack gap={1}>
                            <Typography fontWeight={'500'} color={'var(--text)'}>Full Description :</Typography>
                            <Typography fontWeight={'500'} dangerouslySetInnerHTML={{ __html: serviceDetails?.fullDescription }}></Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ServiceDetails;
