import { openDB } from 'idb';

const DB_NAME = 'my-database';
const STORE_NAME = 'my-store';

async function initDB() {
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
            }
        },
    });
    return db;
}

export async function addData(data) {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    await tx.store.add(data);
    await tx.done;
}

export async function getAllData() {
    const db = await initDB();
    return await db.getAll(STORE_NAME);
}

export async function clearData() {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    await tx.store.clear();
    await tx.done;
}