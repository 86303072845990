import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, TableLoader } from "../../components/Common/Table";
import { MdMoreVert } from "react-icons/md";
import { dummy } from "../../helper/Constant";
import { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { useDeleteThoughtMutation, useGetThoughtListQuery } from "../../services/thought";

export default function Thought() {
    const [page, setPage] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", url: '', delete: '' });
    const { data, error, isLoading } = useGetThoughtListQuery({ page: page + 1 });
    const [deleteThought] = useDeleteThoughtMutation()
    const thoughtList = data?.data;
    return (
        <>
            <Stack>
                <Stack my={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Heading head={'Thought'} />
                    <Button buttonName='Add Thought' onClick={() => setIsModalOpen({ open: true, currentComponent: "addThought", isEdit: false })} />
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Video</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='3' />) :
                            thoughtList && thoughtList.length > 0 ?
                                (thoughtList?.map((ele, i) => {
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                <video
                                                    src={ele?.video && ele?.video}
                                                    alt='preview'
                                                    style={{
                                                        width: '100%',
                                                        height: '200px',
                                                        borderRadius: '10px',
                                                        objectFit: 'contain'
                                                    }}
                                                    // controls
                                                    autoPlay
                                                    muted
                                                />
                                                {/* <img src={ ? ele?.video : dummy} alt="banner_image" width={'300px'} /> */}
                                            </TableCell>
                                            <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "addThought", isEdit: true, id: ele?.id, url: ele?.video })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Thought", para: "Are you sure you want to delete this thought?", id: ele?.id, delete: deleteThought })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Delete</Box></Stack>
                                                            </DropdownMenu.Item>
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan='3' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {thoughtList && thoughtList.length > 0 ? <Pagination totalData={data?.total || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", url: '', delete: '' })}
            />
        </>
    );
}
