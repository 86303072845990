import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BackButton, Button, ErrorMessage, Heading, Input, Select, UploadImage } from '../../components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { bloodGroupList, countryList } from '../../helper';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { useGetCityListQuery, useGetStateListQuery } from '../../services/Location';
import { useAddNewAdminMutation, useUpdateAdminMutation } from '../../services/Admin';

const AddAdmin = () => {
  const { showMessage } = useMessage();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState([]);
  const [country, setCountry] = useState(1);
  const [states, setStates] = useState(null);
  const [city, setCity] = useState(null);
  const [aadharImage, setAadharImage] = useState([]);
  const [bloodGroup, setBloodGroup] = useState(null);
  const [formValues, setFormValues] = useState({
    name: '',
    mobile: '',
    email: '',
    guardianName: '',
    guardianMobile: '',
    address: '',
    pincode: '',
    panNo: '',
    dob: '',
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [profileErrorMessage, setProfileErrorMessage] = useState('');
  const [addharErrorMessage, setAddharErrorMessage] = useState('');

  const { data: cityLst, error: cityError, isLoading: cityLoading } = useGetCityListQuery(states, { skip: !states });
  const cityList = cityLst?.data;
  const { data: stateLst, error: stateError, isLoading: stateLoading } = useGetStateListQuery();
  const stateList = stateLst?.data;

  const handleStateChange = (e) => setStates(e.target.value)
  const handleCountryChange = (e) => setCountry(e.target.value)
  const handleCityChange = (e) => setCity(e.target.value)
  const handleBloodGroupChange = (e) => setBloodGroup(e.target.value);

  const [addAdmin] = useAddNewAdminMutation();
  const [updateAdmin] = useUpdateAdminMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateField = (field, fieldName) => {
    if (!field) {
      setErrorMessages(prevState => ({ ...prevState, [fieldName]: `${fieldName} is a mandatory field.` }));
      return true;
    }
    setErrorMessages(prevState => ({ ...prevState, [fieldName]: '' }));
    return false;
  };

  const handleFormSubmit = async () => {
    // Reset all error messages
    setErrorMessages({});
    setProfileErrorMessage('');
    setAddharErrorMessage('');

    // Validation for mandatory fields
    let hasError = false;
    if (profileImage.length === 0) {
      setProfileErrorMessage('Please select a profile image to upload.');
      hasError = true;
    }

    if (aadharImage.length === 0) {
      setAddharErrorMessage('Please select a addhar image to upload.');
      hasError = true;
    }

    // Validate fields
    const nameError = validateField(formValues.name, 'name');
    const mobileError = validateField(formValues.mobile, 'mobile');
    const emailError = validateField(formValues.email, 'email');
    const guardianNameError = validateField(formValues.guardianName, 'guardianName');
    const guardianMobileError = validateField(formValues.guardianMobile, 'guardianMobile');
    const countryError = validateField(country, 'country');
    const stateError = validateField(states, 'state');
    const cityError = validateField(city, 'city');
    const addressError = validateField(formValues.address, 'address');
    const panNoError = validateField(formValues.panNo, 'panNo');
    const dobError = validateField(formValues.dob, 'dob');
    const bloodGroupError = validateField(bloodGroup, 'bloodGroup');

    // If any field has an error, prevent form submission
    if (
      nameError || mobileError || emailError || guardianNameError || guardianMobileError ||
      countryError || stateError || cityError || addressError || hasError ||
      panNoError || dobError || bloodGroupError
    ) {
      return;
    }


    const formData = new FormData();
    formData.append('profileImage', profileImage);
    formData.append('aadharImage', aadharImage);
    formData.append('bloodGroup', bloodGroup);

    formData.append('name', formValues.name);
    formData.append('mobile', formValues.mobile);
    formData.append('email', formValues.email);
    formData.append('guardianName', formValues.guardianName);
    formData.append('guardianMobile', formValues.guardianMobile);
    formData.append('country', country);
    formData.append('state', states);
    formData.append('city', city);
    formData.append('address', formValues.address);
    formData.append('pincode', 'pincode');
    formData.append('panNo', formValues.panNo);
    formData.append('dob', formValues.dob);

    try {
      let response;

      if (state) {
        response = await updateAdmin({ formData, id: state.id });
      } else {
        response = await addAdmin(formData);
      }

      const { status, message } = response?.data || {};
      if (status) {
        navigate('/admin');
        showMessage('success', message);
      } else {
        showMessage('error', message);

      }
    } catch (error) {
      console.error('Failed to save the service:', error);
    }
  };

  useEffect(() => {
    if (state) {
      setFormValues({
        name: state?.data?.name,
        mobile: state?.data?.mobile,
        email: state?.data?.email,
        guardianName: state?.data?.guardianName,
        guardianMobile: state?.data?.guardianMobile,
        address: state?.data?.address,
        pincode: state?.data?.pincode,
        panNo: state?.data?.panNo,
        dob: new Date(state?.data?.dob).toISOString().split('T')[0],
        id: state.id,
      });
      setProfileImage(state?.data?.profileImage);
      setAadharImage(state?.data?.aadharImage);
      setBloodGroup(state?.data?.bloodGroup);
      setStates(state?.data?.state);
      setCity(state?.data?.city);
      // setCountry(state?.data?.country);
    }
  }, [state]);

  return (
    <>
      <Stack flexDirection={'row'} gap={1} mb={1}>
        <BackButton />
        <Heading head='Add Admin' />
      </Stack>
      <Box className='card_container'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>User Photo</Typography>
            <UploadImage files={profileImage} setFiles={setProfileImage} />
            <ErrorMessage message={profileErrorMessage} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput='Name' name="name" value={formValues.name} onChange={handleInputChange} />
            {errorMessages.name && <ErrorMessage message={errorMessages.name} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput='Mobile' type='number' name="mobile" value={formValues.mobile} onChange={handleInputChange} />
            {errorMessages.mobile && <ErrorMessage message={errorMessages.mobile} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput='Email' type='email' name="email" value={formValues.email} onChange={handleInputChange} />
            {errorMessages.email && <ErrorMessage message={errorMessages.email} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput='Guardian Name' name="guardianName" value={formValues.guardianName} onChange={handleInputChange} />
            {errorMessages.guardianName && <ErrorMessage message={errorMessages.guardianName} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput='Guardian Mobile' name="guardianMobile" value={formValues.guardianMobile} onChange={handleInputChange} />
            {errorMessages.guardianMobile && <ErrorMessage message={errorMessages.guardianMobile} />}
          </Grid>
          <Grid item xs={12}>
            <Input labelinput='Address' name="address" value={formValues.address} onChange={handleInputChange} />
            {errorMessages.address && <ErrorMessage message={errorMessages.address} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Select selectList={countryList} labelinput='Country' name="workType" value={country} onChange={handleCountryChange} />
            {errorMessages.country && <ErrorMessage message={errorMessages.country} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Select selectList={stateLoading ? [] : stateList} labelinput='State' name="workType" value={states} onChange={handleStateChange} />
            {errorMessages.state && <ErrorMessage message={errorMessages.state} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Select selectList={cityLoading ? [] : cityList} labelinput='City' name="workType" value={city} onChange={handleCityChange} />
            {errorMessages.city && <ErrorMessage message={errorMessages.city} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input labelinput='Pan Card No' name="panNo" value={formValues.panNo} onChange={handleInputChange} />
            {errorMessages.panNo && <ErrorMessage message={errorMessages.panNo} />}
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Upload Aadhar Card</Typography>
            <UploadImage files={aadharImage} setFiles={setAadharImage} />
            <ErrorMessage message={addharErrorMessage} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Select selectList={bloodGroupList} labelinput='Blood Group' name="bloodGroup" value={bloodGroup} onChange={handleBloodGroupChange} />
            {errorMessages.bloodGroup && <ErrorMessage message={errorMessages.bloodGroup} />}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input labelinput='User DOB' name="dob" type='date' value={formValues.dob} onChange={handleInputChange} />
            {errorMessages.dob && <ErrorMessage message={errorMessages.dob} />}
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={'row'} gap={1}>
              <Button buttonName='save' onClick={handleFormSubmit} />
              <Button buttonName='cancel' color='white' onClick={() => navigate('/admin')} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddAdmin;
