import { RxDashboard } from 'react-icons/rx';
import { FaRegImage } from "react-icons/fa";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { RiUser4Line } from "react-icons/ri";
import { GrUserWorker } from "react-icons/gr";
import { TbReport } from "react-icons/tb";
import { LuSettings } from "react-icons/lu";
import { LuSettings2 } from "react-icons/lu";
import { RiBuildingLine } from "react-icons/ri";
import { MdOutlineReviews, MdWorkOutline } from "react-icons/md";
import { MdOutlineWorkOff } from "react-icons/md";
import { MdOutlineWorkHistory } from "react-icons/md";
import { IoBan } from "react-icons/io5";
import { PiBoundingBox } from "react-icons/pi";
import { TbMoneybag } from "react-icons/tb";
import { TiStarOutline } from "react-icons/ti";
import { RiAdminLine } from "react-icons/ri";

export const mostBookServide = [
  { name: 'Yes', id: '1' },
  { name: 'No', id: '2' },
]
export const countryList = [
  { name: 'INDIA', id: '1' },
]

export const userTypeList = [
  { name: 'Technician', id: '1' },
  { name: 'User', id: '2' },
]

export const bannerTypeList = [
  { name: 'Top', id: '1' },
  { name: 'Bottom', id: '2' },
]

export const addressTypeList = [
  { name: 'Home', id: 'Home' },
  { name: 'Office', id: 'Office' },
  { name: "Work", id: "Work" },
]

export const bloodGroupList = [
  { name: 'A+', id: 'A+' },
  { name: 'A-', id: 'A-' },
  { name: 'B+', id: 'B+' },
  { name: 'B-', id: 'B-' },
  { name: 'O+', id: 'O+' },
  { name: 'O-', id: 'O-' },
  { name: 'AB+', id: 'AB+' },
  { name: 'AB-', id: 'AB-' },
]

export const checkPermission = (permissonData, role) => {
  const userType = localStorage.getItem("ROLE");
  return permissonData.includes(role || userType);
};

export const SUPERADMIN = "1";

export const ADMIN = "2";

export const SidebarList = [
  {
    name: 'Dashboard',
    icon: <RxDashboard className='sidebar_icon' />,
    activeIcon: <RxDashboard className='sidebar_active_icon' />,
    path: '/dashboard',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN, ADMIN],
  },
  {
    name: 'Admin Mgt',
    icon: <RiAdminLine className='sidebar_icon' />,
    activeIcon: <RiAdminLine className='sidebar_active_icon' />,
    path: '/admin',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN],
  },
  {
    name: 'Banner',
    icon: <FaRegImage className='sidebar_icon' />,
    activeIcon: <FaRegImage className='sidebar_active_icon' />,
    path: '/banner',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN],
  },
  {
    name: 'Thought',
    icon: <HiOutlineVideoCamera className='sidebar_icon' />,
    activeIcon: <HiOutlineVideoCamera className='sidebar_active_icon' />,
    path: '/thought',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN],
  },
  {
    name: 'User Mgt',
    icon: <RiUser4Line className='sidebar_icon' />,
    activeIcon: <RiUser4Line className='sidebar_active_icon' />,
    path: '/user',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN, ADMIN],
  },
  {
    name: 'Empolyees',
    icon: <GrUserWorker className='sidebar_icon' />,
    activeIcon: <GrUserWorker className='sidebar_active_icon' />,
    path: '/empolyees',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN, ADMIN],
  },
  {
    name: 'Service',
    icon: <LuSettings className='sidebar_icon' />,
    activeIcon: <LuSettings className='sidebar_active_icon' />,
    SUBMENU: true,
    PermissionStatus: [SUPERADMIN, ADMIN],
    subMenus: [
      {
        name: 'Service Department',
        icon: <RiBuildingLine className='sidebar_icon' />,
        activeIcon: <RiBuildingLine className='sidebar_active_icon' />,
        path: '/service-department',
        SUBMENU: false,
        PermissionStatus: [SUPERADMIN, ADMIN],
      },
      {
        name: 'Sub Service',
        icon: <LuSettings2 className='sidebar_icon' />,
        activeIcon: <LuSettings2 className='sidebar_active_icon' />,
        path: '/sub-service',
        SUBMENU: false,
        PermissionStatus: [SUPERADMIN, ADMIN],
      },
      {
        name: 'Services',
        icon: <LuSettings className='sidebar_icon' />,
        activeIcon: <LuSettings className='sidebar_active_icon' />,
        path: '/services',
        SUBMENU: false,
        PermissionStatus: [SUPERADMIN, ADMIN],
      },
    ]
  },
  {
    name: 'Jobs',
    icon: <MdWorkOutline className='sidebar_icon' />,
    activeIcon: <MdWorkOutline className='sidebar_active_icon' />,
    SUBMENU: true,
    PermissionStatus: [SUPERADMIN, ADMIN],
    subMenus: [
      {
        name: 'Jobs Management',
        icon: <MdOutlineWorkHistory className='sidebar_icon' />,
        activeIcon: <MdOutlineWorkHistory className='sidebar_active_icon' />,
        path: '/jobs',
        SUBMENU: false,
        PermissionStatus: [SUPERADMIN, ADMIN],
      },
      {
        name: 'Non Verify Job',
        icon: <MdOutlineWorkOff className='sidebar_icon' />,
        activeIcon: <MdOutlineWorkOff className='sidebar_active_icon' />,
        path: '/non-verify-job',
        SUBMENU: false,
        PermissionStatus: [SUPERADMIN, ADMIN],
      },
      {
        name: 'Verify Job',
        icon: <MdWorkOutline className='sidebar_icon' />,
        activeIcon: <MdWorkOutline className='sidebar_active_icon' />,
        path: '/verify-job',
        SUBMENU: false,
        PermissionStatus: [SUPERADMIN, ADMIN],
      },
    ]
  },
  {
    name: 'Reports',
    icon: <TbReport className='sidebar_icon' />,
    activeIcon: <TbReport className='sidebar_active_icon' />,
    path: '/reports',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN, ADMIN],
  },
  {
    name: 'Settings',
    icon: <LuSettings className='sidebar_icon' />,
    activeIcon: <LuSettings className='sidebar_active_icon' />,
    path: '/settings',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN],
  },
  {
    name: 'Slots',
    icon: <LuSettings className='sidebar_icon' />,
    activeIcon: <LuSettings className='sidebar_active_icon' />,
    path: '/slots',
    SUBMENU: false,
    PermissionStatus: [ADMIN],
  },
  {
    name: 'Ratting',
    icon: <MdOutlineReviews className='sidebar_icon' />,
    activeIcon: <MdOutlineReviews className='sidebar_active_icon' />,
    path: '/rattings',
    SUBMENU: false,
    PermissionStatus: [SUPERADMIN],
  },
]

