import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const rattingApis = createApi({
  reducerPath: 'rattingApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Ratting'],
  endpoints: (builder) => ({
    getRattingList: builder.query({
      query: ({ page }) => `rattingList?page=${page}`,
      providesTags: ['Ratting'],
    }),
    rattingApprove: builder.mutation({
      query: (body) => {
        const { approveId } = body;
        return { url: `rattingApprove/${approveId}`, method: "PUT" }
      },
      invalidatesTags: ['Ratting'],
    }),
    addRatting: builder.mutation({
      query: (body) => {
        return { url: `addRatting`, body, method: "POST" }
      },
      invalidatesTags: ['Ratting'],
    }),
    addAdminReview: builder.mutation({
      query: (body) => {
        return { url: `addAdminReview`, body, method: "POST" }
      },
      // invalidatesTags: ['Ratting'],
    }),
  }),
})

export const { useGetRattingListQuery, useAddRattingMutation, useRattingApproveMutation, useAddAdminReviewMutation } = rattingApis