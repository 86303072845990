import React, { createContext, useState, useContext } from 'react';

// Create the context
const MessageContext = createContext();

// Create a provider component
export const MessageProvider = ({ children }) => {
  const [isMessageOpen, setIsMessageOpen] = useState({
    open: false,
    currentSeverity: '',
    message: '',
  });

  const showMessage = (severity, message) => {
    setIsMessageOpen({ open: true, currentSeverity: severity, message });
  };

  const closeMessage = () => {
    setIsMessageOpen({ open: false, currentSeverity: '', message: '' });
  };

  return (
    <MessageContext.Provider value={{ isMessageOpen, showMessage, closeMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook to use the Message context
export const useMessage = () => useContext(MessageContext);
