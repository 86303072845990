import { fetchBaseQuery } from "@reduxjs/toolkit/query";

export const baseQueryWithReauth = async (args, api, extraOptions) => {

    const baseQuery = fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` }
    });

    const result = await baseQuery(args, api, extraOptions);

    if (result?.meta?.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('ROLE');
        alert(result?.error?.data?.data?.reason);
    }
    return result;
};
