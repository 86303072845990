import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal, Select } from "../../components/Common";
import { NoRecordFound, Pagination, Search, TableLoader } from "../../components/Common/Table";
import { MdMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useDeleteServicesMutation, useGetServicesListQuery, useStatusChangeServiceMutation } from "../../services/Services";
import useDebounce from "../../helper/utils";
import { useEffect, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { useMessage } from "../../components/Common/Message/MessageContext";
import { useGetserviceDepartmentDropdownQuery } from "../../services/ServiceDepartment";
import ServiceFilter from "./ServiceFilter";

export default function Services() {
    const navigate = useNavigate();
    const { showMessage } = useMessage();
    const [selectDeptService, setSelectDeptService] = useState('');
    const [page, setPage] = useState(0);
    const [searchData, setSearchData] = useState('')
    const search = useDebounce(searchData, 500)
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", url: '', delete: '' });
    const { data, error, isLoading } = useGetServicesListQuery({ page: page + 1, search, selectDeptService });
    const [deleteServices] = useDeleteServicesMutation();
    const servicesList = data?.data;
    const { data: serviceDeptList, error: serviceDeptError, isLoading: serviceDeptLoading } = useGetserviceDepartmentDropdownQuery();
    const serviceDept = serviceDeptList?.data;
    const handleChange = (e) => setSelectDeptService(e.target.value);


    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [changeStatus] = useStatusChangeServiceMutation();

    const handleStatusChange = async (adminId, adminStatus) => {
        setSelectedAdmin({ id: adminId, status: adminStatus });
        setIsModalOpen({
            open: true,
            currentComponent: "changeStatus",
            head: adminStatus === 1 ? "Block Service" : "Unblock Service",
            para: adminStatus === 1
                ? "Are you sure you want to Block this service?"
                : "Are you sure you want to Unblock this service?"
        });
    };

    const handleStatusChangeSubmit = async () => {

        try {
            const response = await changeStatus({
                id: selectedAdmin.id,
            });

            const { status, message } = response.data || {};

            if (status) {
                showMessage('success', message);
                // Refresh the admin list or update the local state
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while changing status');
        }

        setIsModalOpen({ open: false, currentComponent: "", head: '' });
        setSelectedAdmin(null);
    };

    const userType = localStorage.getItem("ROLE");

    const handleServiceFilter = (serviceFilter) => {
        setSelectDeptService(serviceFilter);
    }

    const lastPage = localStorage.getItem("lastpage");
    const serviceDep = localStorage.getItem("serviceDept");

    useEffect(() => {
        if (lastPage) setPage(+lastPage);
        if (serviceDep) setSelectDeptService(serviceDep);
    }, [lastPage, serviceDep]);

    return (
        <>
            <Stack>
                <Stack flexDirection={{ xs: 'row' }} gap={1} justifyContent={'space-between'} alignItems={{ xs: 'center' }}>
                    <Heading head={'Job Services'} />
                    <Stack flexDirection={{ xs: 'row' }} alignItems={'center'} gap={1} className="services_head">
                        {/* <ServiceFilter selectList={serviceDeptLoading ? [] : serviceDept} value={selectDeptService} onChange={handleChange} /> */}
                        {userType === '2' ? '' : <Button buttonName='Add Service' onClick={() => {
                            localStorage.removeItem('lastpage')
                            localStorage.removeItem('serviceDept')
                            navigate('/add-services')
                        }} />}
                    </Stack>
                </Stack>
                <Stack my={1} flexDirection={{ xs: 'column-reverse', md: 'row' }} gap={1} justifyContent={'space-between'} alignItems={{ xs: 'flex-end', md: 'center' }}>
                    <Search setSearch={setSearchData} search={searchData} setPage={setPage} />
                    {serviceDeptLoading ? '' :
                        <Stack flexDirection={'row'} flexWrap={'wrap'} justifyContent={'flex-end'} gap={'5px'}>
                            <div onClick={() => handleServiceFilter('')}
                                className={`${selectDeptService === '' ? 'filter_active_button' : ''} filter_button`}
                            >All</div>
                            {serviceDept?.map((ele, i) => {
                                return <div key={i} onClick={() => handleServiceFilter(ele.serviceName)}
                                    className={`${selectDeptService === ele.serviceName ? 'filter_active_button' : ''} filter_button`}
                                >{ele.serviceName}</div>
                            })}
                        </Stack>
                    }
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>IMAGE</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Service Name</TableCell>
                            <TableCell>Service Category</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Offer %</TableCell>
                            {userType === '2' ? '' : <TableCell>Block/Unblock</TableCell>}
                            {userType === '2' ? '' : <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan={userType === '2' ? '7' : '9'} />) :
                            servicesList && servicesList.length > 0 ?
                                (servicesList?.map((ele, i) => {
                                    const { serviceName, serviceDepartmentName, image, status, id, subserviceName, offer, servicePrice } = ele;
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}
                                            onClick={() => navigate('/view-service', { state: { id: ele?.id, data: ele } })}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                <img src={image} alt="banner_image" width={'70px'} height='70px' style={{ objectFit: 'cover' }} />
                                            </TableCell>
                                            <TableCell>{serviceName}</TableCell>
                                            <TableCell>{serviceDepartmentName}</TableCell>
                                            <TableCell>{subserviceName}</TableCell>
                                            <TableCell>&#8377; {servicePrice}</TableCell>
                                            <TableCell>{offer}</TableCell>
                                            {userType === '2' ? '' : <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Switch
                                                    checked={status === 1}
                                                    onClick={() => handleStatusChange(id, status)}
                                                />
                                            </TableCell>}
                                            {userType === '2' ? '' : <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => {
                                                                localStorage.setItem('lastpage', page)
                                                                localStorage.setItem('serviceDept', selectDeptService)
                                                                navigate('/add-services', { state: { id: ele?.id, data: ele } })
                                                            }}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            {/* <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Service", para: "Are you sure you want to delete this Service?", id: ele?.id, delete: deleteServices })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Delete</Box></Stack>
                                                            </DropdownMenu.Item> */}
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan={userType === '2' ? '7' : '9'} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {servicesList && servicesList.length > 0 ? <Pagination totalData={data?.totalData || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                onSubmit={handleStatusChangeSubmit}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' })}
            />
        </>
    );
}
