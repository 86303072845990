import React, { useEffect, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import { Button, ErrorMessage } from '../../components/Common'
import { useAddNewThoughtMutation, useUpdateThoughtMutation } from '../../services/thought'
import UploadVideo from '../../components/Common/UploadVideo'
import { useMessage } from '../../components/Common/Message/MessageContext'

const AddThought = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();

    const [files, setFiles] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [addThought] = useAddNewThoughtMutation()
    const [updateThought] = useUpdateThoughtMutation()

    const handleSubmit = async () => {
        if (!files) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('video', files);

        try {
            let response;
            if (modalOpen.isEdit) {
                response = await updateThought({ formData, id: modalOpen.id });
            } else {
                response = await addThought(formData);
            }

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
            handleClose();
            setFiles('');
            setErrorMessage('');
        }
    };

    useEffect(() => {
        if (modalOpen.url) {
            setFiles(modalOpen.url);
        }
    }, [modalOpen?.url]);

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : "Add"} Thought
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <UploadVideo setFiles={setFiles} files={files} setErrorMessage={setErrorMessage} />
                        <ErrorMessage message={errorMessage} /> {/* Use the ErrorMessage component */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    )
}

export default AddThought