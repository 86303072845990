import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { IoCamera } from 'react-icons/io5';

const UploadImage = ({ files, setFiles }) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (files.length > 0) {
            setImage(files);
        } else {
            setImage(null);
        }
    }, [files]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFiles(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setFiles("");
    };

    return (
        <Box
            sx={{
                position: "relative",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "6px",
                width: "100px",
                height: "100px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {image ? (
                <div
                    className="cv_uploaded_image"
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="cv_uploaded_image_trash">
                        <FaTrashCan
                            style={{ position: "absolute", color: "#fff" }}
                            onClick={handleRemoveImage}
                        />
                    </div>
                    <img
                        src={image || "images/icon"}
                        alt="Selected Image"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            zIndex: 1,
                            objectFit: "cover",
                        }}
                    />
                </div>
            ) : (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IoCamera
                        style={{ fontSize: "70px", color: "rgba(0, 0, 0, 0.12)" }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                            opacity: "0",
                        }}
                    />
                </div>
            )}
        </Box>
    )
}

export default UploadImage;
