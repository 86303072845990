import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const jobsApis = createApi({
  reducerPath: 'jobsApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Jobs'],
  endpoints: (builder) => ({
    getJobList: builder.query({
      query: ({ page, search, orderStatus, type }) => `jobList?page=${page}&search=${search}&orderStatus=${orderStatus}&type=${type}`,
      providesTags: ['Jobs'],
    }),
    getjobDetail: builder.query({
      query: ({ id }) => `jobDetail/${id}`,
      providesTags: ['Jobs'],
    }),
    addNewJob: builder.mutation({
      query: (body) => {
        return { url: `addJob`, body, method: "POST" }
      },
      invalidatesTags: ['Jobs'],
    }),
    updateJob: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `updateJob/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Jobs'],
    }),
    addemployeeJobAssign: builder.mutation({
      query: (body) => {
        return { url: `employeeJobAssign`, body, method: "POST" }
      },
      invalidatesTags: ['Jobs'],
    }),
  }),
})

export const { useAddNewJobMutation, useGetJobListQuery, useGetjobDetailQuery, useAddemployeeJobAssignMutation,
  useUpdateJobMutation } = jobsApis