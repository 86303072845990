import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const serviceDeptApis = createApi({
  reducerPath: 'serviceDeptApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ServiceDept'],
  endpoints: (builder) => ({
    getServiceDeptList: builder.query({
      query: ({ page, search }) => `serviceDepartmentList?page=${page}&perPageSize=10&search=${search}`,
      providesTags: ['ServiceDept'],
    }),
    getserviceDepartmentDropdown: builder.query({
      query: () => `serviceDepartmentDropdown`,
      providesTags: ['ServiceDept'],
    }),
    addNewServiceDept: builder.mutation({
      query: (body) => {
        return { url: `addServiceDepartment`, body, method: "POST" }
      },
      invalidatesTags: ['ServiceDept'],
    }),
    updateServiceDept: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `updateServiceDepartment/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['ServiceDept'],
    }),
    deleteServiceDept: builder.mutation({
      query: (id) => {
        return { url: `deleteServiceDepartment/${id}`, method: "DELETE" }
      },
      invalidatesTags: ['ServiceDept'],
    }),
    statusChangeServiceDepartment: builder.mutation({
      query: (body) => {
        const { id } = body;
        return { url: `statusChangeServiceDepartment/${id}`, method: "PUT" }
      },
      invalidatesTags: ['ServiceDept'],
    }),
  }),
})

export const { useGetServiceDeptListQuery, useGetserviceDepartmentDropdownQuery, useAddNewServiceDeptMutation,
  useDeleteServiceDeptMutation, useUpdateServiceDeptMutation, useStatusChangeServiceDepartmentMutation } = serviceDeptApis