import { getFresherPerferenceLists } from "./services/Notification";

export const useUserNotificationSubscribeHook = () => {
    // const [addSubscription] = registerAndSubscribe()

    // const { addUserSubscriptionAPI } = useAddUserSubscriptionAPI();

    async function regSw() {

        if ('serviceWorker' in navigator) {
            const url = `https://home-hancer-admin.vercel.app/sw.js`;
            const reg =
                await navigator.serviceWorker.register(url, { scope: '/' });
            return reg;
        }
        throw Error('serviceWorker not supported');
    }

    async function subscribe(serviceWorkerReg, notificationData) {
        try {

            let subscription = await serviceWorkerReg.pushManager.getSubscription();
            if (subscription === null) {
                subscription = await serviceWorkerReg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BJ9OXOUjV3lfJzNC8cjgHNRqxXdNBObYBXTjCWfRmvnlyx90EDWO6lpC1fteYbFZLMe_I8M2yFQ_EqbRiovwOvU",
                });
                const payload = {
                    subscription: JSON.stringify(subscription),
                    userId: notificationData.userId,
                    type: notificationData.type,
                }

                const { data } = await getFresherPerferenceLists(payload);
            }
        } catch (error) {
            console.log('Error subscribing to push notifications:', error);
        }

    }

    async function registerAndSubscribe(data) {
        try {
            const serviceWorkerReg = await regSw();
            await subscribe(serviceWorkerReg, data);
        } catch (error) {
            return error;
        }
    }

    return { regSw, subscribe, registerAndSubscribe };
};
