import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, Search, TableLoader } from "../../components/Common/Table";
import { MdMoreVert } from "react-icons/md";
import { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AiOutlineEdit } from "react-icons/ai";
import { useDeleteSubServiceMutation, useGetSubServiceListQuery, useStatusChangeSubServiceMutation } from "../../services/SubServices";
import { HiOutlineTrash } from "react-icons/hi";
import useDebounce from "../../helper/utils";
import { useMessage } from "../../components/Common/Message/MessageContext";


export default function SubService() {
    const { showMessage } = useMessage();
    const [page, setPage] = useState(0)

    const [searchData, setSearchData] = useState('')
    const search = useDebounce(searchData, 500)
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false });

    const { data, error, isLoading } = useGetSubServiceListQuery({ page: page + 1, search });
    const [deleteSubService] = useDeleteSubServiceMutation();

    const subServiceList = data?.data;

    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [changeStatus] = useStatusChangeSubServiceMutation();

    const handleStatusChange = async (adminId, adminStatus) => {
        setSelectedAdmin({ id: adminId, status: adminStatus });
        setIsModalOpen({
            open: true,
            currentComponent: "changeStatus",
            head: adminStatus === 1 ? "Block Sub Service" : "Unblock Sub Service",
            para: adminStatus === 1
                ? "Are you sure you want to Block this sub service?"
                : "Are you sure you want to Unblock this sub service?"
        });
    };

    const handleStatusChangeSubmit = async () => {

        try {
            const response = await changeStatus({
                id: selectedAdmin.id,
            });

            const { status, message } = response.data || {};

            if (status) {
                showMessage('success', message);
                // Refresh the admin list or update the local state
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while changing status');
        }

        setIsModalOpen({ open: false, currentComponent: "", head: '' });
        setSelectedAdmin(null);
    };

    const userType = localStorage.getItem("ROLE");

    return (
        <>
            <Stack>
                <Heading head={'Sub Service'} />
                <Stack my={1} flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={1} justifyContent={'space-between'} alignItems={{ xs: 'flex-end', sm: 'center' }}>
                    <Search setSearch={setSearchData} search={searchData} setPage={setPage} />
                    {userType === '2' ? '' : <Button buttonName='Add Sub Service' onClick={() => setIsModalOpen({ open: true, currentComponent: "addSubService", isEdit: false })} />}
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Sub Service Name</TableCell>
                            <TableCell>Service Name</TableCell>
                            {userType === '2' ? '' : <TableCell>Block/Unblock</TableCell>}
                            {userType === '2' ? '' : <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan={userType === '2' ? '3' : '5'} />) :
                            subServiceList && subServiceList.length > 0 ?
                                (subServiceList?.map((ele, i) => {
                                    const { subServiceName, status, id, serviceDepartmentName } = ele;
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{subServiceName}</TableCell>
                                            <TableCell>{serviceDepartmentName}</TableCell>
                                            {userType === '2' ? '' : <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Switch
                                                    checked={status === 1}
                                                    onClick={() => handleStatusChange(id, status)}
                                                />
                                            </TableCell>}
                                            {userType === '2' ? '' : <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "addSubService", isEdit: true, id: ele?.id, data: ele })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            {/* <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Banner", para: "Are you sure you want to delete this banner?", id: ele?.id, delete: deleteSubService })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Delete</Box></Stack>
                                                            </DropdownMenu.Item> */}
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan={userType === '2' ? '3' : '5'} />}

                    </TableBody>
                </Table>
            </TableContainer>
            {subServiceList && subServiceList.length > 0 ? <Pagination totalData={data?.totalData || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                onSubmit={handleStatusChangeSubmit}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false })}
            />
        </>
    );
}
