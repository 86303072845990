import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button, Input } from '../../Common';

const ChangePassword = ({ handleClose, modalOpen }) => {

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {"ChangePassword"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container gap={2} maxWidth={{ sm: '400px' }} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput={'CurrentPassword'}
              passwordinput
              name='currentPassword'
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput={'NewPassword'}
              passwordinput
              name='newPassword'
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput={'ConfirmPassword'}
              passwordinput
              name='confirmpass'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
        <Button buttonName={'Save'} size='small' onClick={handleClose} />
      </DialogActions>
    </>
  )
}

export default ChangePassword