import React, { useEffect, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import { Button, ErrorMessage, Input, Select } from '../../components/Common'
import { useGetserviceDepartmentDropdownQuery } from '../../services/ServiceDepartment'
import { useMessage } from '../../components/Common/Message/MessageContext'
import { useAddNewSubServiceMutation, useUpdateSubServiceMutation } from '../../services/SubServices'

const AddSubService = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();

    const [name, setName] = useState('');
    const [selectService, setSelectService] = useState('');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [serviceErrorMessage, setServiceErrorMessage] = useState('');
    const handleChange = (e) => {
        setSelectService(e.target.value);
    }
    const { data, error, isLoading } = useGetserviceDepartmentDropdownQuery();
    const [addSubService] = useAddNewSubServiceMutation();
    const [updateSubService] = useUpdateSubServiceMutation();
    const serviceList = data?.data;

    const handleSubmit = async () => {
        // Reset error messages
        setNameErrorMessage('');
        setServiceErrorMessage('');

        // Validation for mandatory fields
        let hasError = false;
        if (!name) {
            setNameErrorMessage('Name is a mandatory field.');
            hasError = true;
        }
        if (!selectService) {
            setServiceErrorMessage('Service is a mandatory field.');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const formData = {
            serviceDepartmentId: selectService,
            subServiceName: name
        };

        try {
            let response;
            if (modalOpen.isEdit) {
                response = await updateSubService({ ...formData, id: modalOpen.id });
            } else {
                response = await addSubService(formData);
            }

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
            handleClose();
        }
    };

    useEffect(() => {
        if (modalOpen.data) {
            setName(modalOpen?.data?.subServiceName);
            setSelectService(modalOpen?.data?.serviceDepartmentId);
        }
    }, [modalOpen?.data]);

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : "Add"} Sub Service
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Select selectList={isLoading ? [] : serviceList} value={selectService} onChange={handleChange} labelinput='Category' selectHead='Select Category' />
                        <ErrorMessage message={serviceErrorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <ErrorMessage message={nameErrorMessage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    )
}

export default AddSubService