import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils'

export const adminApis = createApi({
  reducerPath: 'adminApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Admin'],
  endpoints: (builder) => ({
    getAdminList: builder.query({
      query: ({ page }) => `adminList?page=${page}`,
      providesTags: ['Admin'],
    }),
    getAdminDetail: builder.query({
      query: (id) => `getAdminDetail/${id}`,
    }),
    addNewAdmin: builder.mutation({
      query: (body) => {
        return { url: `createAdmin`, body, method: "POST" }
      },
      invalidatesTags: ['Admin'],
    }),
    sendLoginOtp: builder.mutation({
      query: (body) => {
        return { url: `sendLoginOtp`, body, method: "POST" }
      },
      invalidatesTags: ['Admin'],
    }),
    verifyLoginOtp: builder.mutation({
      query: (body) => {
        return { url: `verifyLoginOtp`, body, method: "POST" }
      },
      invalidatesTags: ['Admin'],
    }),
    updateAdmin: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `updateAdmin/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Admin'],
    }),
    deleteAdmin: builder.mutation({
      query: (id) => {
        return { url: `deleteAdmin/${id}`, method: "DELETE" }
      },
      invalidatesTags: ['Admin'],
    }),
    statusChangeAdmin: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `statusChangeAdmin/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Admin'],
    }),
  }),
})

export const { useGetAdminListQuery, useGetAdminDetailQuery, useAddNewAdminMutation, useSendLoginOtpMutation,
  useVerifyLoginOtpMutation, useUpdateAdminMutation, useDeleteAdminMutation, useStatusChangeAdminMutation } = adminApis