import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const employeeApis = createApi({
  reducerPath: 'employeeApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Employee'],
  endpoints: (builder) => ({
    getEmployeeList: builder.query({
      query: ({ page, search }) => `employeeList?page=${page}&search=${search}`,
      providesTags: ['Employee'],
    }),
    addNewEmployee: builder.mutation({
      query: (body) => {
        return { url: `addEmployee`, body, method: "POST" }
      },
      invalidatesTags: ['Employee'],
    }),
    employeeDropdown: builder.mutation({
      query: (body) => {
        return { url: `employeeDropdown`, body, method: "POST" }
      },
      invalidatesTags: ['Employee'],
    }),
    updateEmployee: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `updateEmployee/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Employee'],
    }),
    deleteEmployee: builder.mutation({
      query: (id) => {
        return { url: `deleteEmployee/${id}`, method: "DELETE" }
      },
      invalidatesTags: ['Employee'],
    }),
    statusChangeEmployee: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `statusChangeEmployee/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Employee'],
    }),
  }),
})

export const { useGetEmployeeListQuery, useEmployeeDropdownMutation, useAddNewEmployeeMutation, useDeleteEmployeeMutation,
  useUpdateEmployeeMutation, useStatusChangeEmployeeMutation } = employeeApis