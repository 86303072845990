import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import { store } from './store'
import { Provider, useSelector } from 'react-redux'
// const Login = React.lazy(() => import('./pages/Login'));
import Login from "./pages/Login";
import Layout from "./components/Layout/Layout";
import Dashboard from "./pages/Dashboard";
import Banner from "./pages/Banner";
import Thought from "./pages/Thought";
import Empolyees from "./pages/Empolyees";
import ServiceDepartment from "./pages/ServiceDepartment";
import SubService from "./pages/SubService";
import Services from "./pages/Services";
import JobsManagement from "./pages/JobsManagement";
import NonVerifyJob from "./pages/NonVerifyJob";
import VerifyJob from "./pages/VerifyJob";
import Reports from "./pages/Reports";
import AddEmpolyees from "./pages/Empolyees/AddEmpolyees";
import AddService from "./pages/Services/AddService";
import AddJob from "./pages/JobsManagement/AddJob";
import JobDetails from "./pages/JobsManagement/JobDetails";
import Profile from "./pages/Profile";

import "./App.css";
import Message from "./components/Common/Message/Message";
import { MessageProvider } from "./components/Common/Message/MessageContext";
import Settings from "./pages/Settings";
import AdminMgt from "./pages/AdminMgt";
import { ADMIN, SUPERADMIN } from "./helper";
import PrivateRoute from "./Routes/PrivateRoute";
import PageNotFound from "./pages/404Page/PageNotFound";
import PublicRoute from "./Routes/PublicRoute";
import User from "./pages/User";
import Slot from "./pages/Slot";
import AddAdmin from "./pages/AdminMgt/AddAdmin";
import Review from "./pages/Review";
import EditJob from "./pages/JobsManagement/EditJob";
import ServiceDetails from "./pages/Services/ServiceDetails";

const pages = [
  {
    name: 'dashboard',
    path: 'dashboard',
    element: <Dashboard />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'admin',
    path: 'admin',
    element: <AdminMgt />,
    permission: [SUPERADMIN],
  },
  {
    name: 'add-admin',
    path: 'add-admin',
    element: <AddAdmin />,
    permission: [SUPERADMIN],
  },
  {
    name: 'banner',
    path: 'banner',
    element: <Banner />,
    permission: [SUPERADMIN],
  },
  {
    name: 'thought',
    path: 'thought',
    element: <Thought />,
    permission: [SUPERADMIN],
  },
  {
    name: 'user',
    path: 'user',
    element: <User />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'empolyees',
    path: 'empolyees',
    element: <Empolyees />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'add-empolyees',
    path: 'add-empolyees',
    element: <AddEmpolyees />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'service-department',
    path: 'service-department',
    element: <ServiceDepartment />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'sub-service',
    path: 'sub-service',
    element: <SubService />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'services',
    path: 'services',
    element: <Services />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'add-services',
    path: 'add-services',
    element: <AddService />,
    permission: [SUPERADMIN],
  },
  {
    name: 'view-service',
    path: 'view-service',
    element: <ServiceDetails />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'jobs',
    path: 'jobs',
    element: <JobsManagement />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'add-job',
    path: 'add-job',
    element: <AddJob />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'view-job',
    path: 'view-job',
    element: <JobDetails />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'edit-job',
    path: 'edit-job',
    element: <EditJob />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'non-verify- job',
    path: 'non-verify-job',
    element: <NonVerifyJob />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'verify-job',
    path: 'verify-job',
    element: <VerifyJob />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'reports',
    path: 'reports',
    element: <Reports />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'profile',
    path: 'profile',
    element: <Profile />,
    permission: [SUPERADMIN, ADMIN],
  },
  {
    name: 'settings',
    path: 'settings',
    element: <Settings />,
    permission: [SUPERADMIN],
  },
  {
    name: 'slots',
    path: 'slots',
    element: <Slot />,
    permission: [ADMIN],
  },
  {
    name: 'rattings',
    path: 'rattings',
    element: <Review />,
    permission: [SUPERADMIN],
  },
]

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MessageProvider>
          <div className="background_color">
            {/* <Suspense fallback={<Loader />} > */}
            <Routes>
              <Route path="/" element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              } />
              {pages?.map((page, index) => {
                const { name, path, element, permission } = page;
                return (
                  <Route key={index} exact path={`/${path}`} name={name} element={
                    <PrivateRoute name={name} permissionStatus={permission}>
                      <Layout>{element}</Layout>
                    </PrivateRoute>
                  } />
                )
              })}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            {/* </Suspense> */}
          </div>
          <Message />
        </MessageProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
