import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import { Button, ErrorMessage, FileUpload, Input, UploadImage } from '../../components/Common';
import { useAddNewServiceDeptMutation, useUpdateServiceDeptMutation } from '../../services/ServiceDepartment';
import { useMessage } from '../../components/Common/Message/MessageContext';
import MultipleUpload from '../../components/Common/MultipleUpload';

const AddServiceDept = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();

    const [name, setName] = useState('');
    const [files, setFiles] = useState([]);
    const [banner, setBanner] = useState([]);
    const [deletedFilesIds, setDeletedFilesIds] = useState([]);
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const [bannerErrorMessage, setBannerErrorMessage] = useState('');

    const [addServiceDept] = useAddNewServiceDeptMutation();
    const [updateServiceDept] = useUpdateServiceDeptMutation();

    const handleSubmit = async () => {
        // Reset error messages
        setNameErrorMessage('');
        setFileErrorMessage('');
        setBannerErrorMessage('');

        // Validation for mandatory fields
        let hasError = false;
        if (!name) {
            setNameErrorMessage('Name is a mandatory field.');
            hasError = true;
        }
        if (files.length === 0) {
            setFileErrorMessage('Please select a file to upload.');
            hasError = true;
        }
        if (!banner) {
            setBannerErrorMessage('Please select a banner image to upload.');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const formData = new FormData();
        formData.append('image', files);
        banner.forEach(file => {
            formData.append('bannerImage', file);
        });
        formData.append('serviceName', name);
        formData.append('deletedImageIds', deletedFilesIds?.length === 0 ? '' : JSON.stringify(deletedFilesIds));

        try {
            let response;
            if (modalOpen.isEdit) {
                response = await updateServiceDept({ formData, id: modalOpen.id });
            } else {
                response = await addServiceDept(formData);
            }

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
                handleClose();
                setFiles([]);
                setBanner([]);
                setName('');
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
        }
    };

    useEffect(() => {
        if (modalOpen.data) {
            setFiles(modalOpen?.data?.image);
            setBanner(modalOpen?.data?.imageData || []);
            setName(modalOpen?.data?.serviceName);
        }
    }, [modalOpen?.data]);

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Add'} Service Department
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>
                            Service Image
                        </Typography>
                        <UploadImage files={files} setFiles={setFiles} />
                        <ErrorMessage message={fileErrorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <ErrorMessage message={nameErrorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>
                            Upload Banner Images
                        </Typography>
                        <MultipleUpload setFiles={setBanner} files={banner} setErrorMessage={setBannerErrorMessage} setDeletedFilesIds={setDeletedFilesIds} />
                        <ErrorMessage message={bannerErrorMessage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    );
};

export default AddServiceDept;
