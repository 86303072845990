import { Box, ListItem, ListItemButton, ListItemIcon, List, ListItemText, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { SidebarList, checkPermission } from '../../helper';

const Sidebar = ({ handleDrawerToggle, drawerStatus, activeSubMenu, setActiveSubMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSubMenu = (name) => {
    if (activeSubMenu === name) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(name);
    }
  };

  const isActive = (path, subPath = []) => {
    return location.pathname === path || subPath.includes(location.pathname);
  };

  const handleNavigation = (path) => {
    localStorage.removeItem('lastpage')
    navigate(path);
    if (path) {
      handleDrawerToggle();
    }
  };

  const renderSubMenu = (subMenus) => {
    return subMenus.map((subMenu, index) => (
      <ListItem key={index} onClick={() => {
        localStorage.removeItem('lastpage');
        navigate(subMenu.path)
      }} disablePadding sx={{ my: '3px' }}>
        <ListItemButton onClick={handleDrawerToggle} sx={{ gap: 1, p: '11px', justifyContent: 'center', borderRadius: '6px', backgroundColor: `${location.pathname === subMenu.path ? 'white.main' : ''}`, background: location.pathname === subMenu.path ? 'var(--primary) !important' : 'transparent !important', }}
          className={location.pathname === subMenu.path ? 'active_sidebar' : ''}>
          <ListItemIcon sx={{ minWidth: 'unset', }}>{location.pathname === subMenu.path ? subMenu.activeIcon : subMenu.icon}</ListItemIcon>
          {drawerStatus &&
            <ListItemText primary={`${subMenu.name.slice(0, 15)}${subMenu.name.length > 15 ? '...' : ''}`} className='sidebar_text' sx={{
              margin: '0', transition: 'width 0.3s ease-in-out', textWrap: 'nowrap',
              color: `${location.pathname === subMenu.path ? 'white.main' : 'black.main'}`
            }} />}
        </ListItemButton>
      </ListItem>
    ));
  };

  const renderListItems = (sidebarItem) => {
    const { name, icon, activeIcon, path, SUBMENU, subMenus } = sidebarItem;
    return (
      <List sx={{ padding: '0', my: '5px', mx: 1 }}>
        <ListItem onClick={() => { handleNavigation(path) }} disablePadding>
          <ListItemButton onClick={() => toggleSubMenu(name)} sx={{ gap: 1, p: '11px', justifyContent: 'center', borderRadius: '6px', backgroundColor: `${location.pathname === path ? 'white.main' : ''}`, background: location.pathname === path ? 'var(--primary) !important' : 'transparent !important', }} className={location.pathname === path ? 'active_sidebar' : ''}>
            <ListItemIcon sx={{ minWidth: 'unset', }}>{location.pathname === path ? activeIcon : icon}</ListItemIcon>
            {drawerStatus &&
              <ListItemText primary={`${name.slice(0, 15)}${name.length > 15 ? '...' : ''}`} className='sidebar_text' sx={{ margin: '0', color: `${location.pathname === path ? 'white.main' : 'black.main'}` }} />}
            {(drawerStatus && SUBMENU) && (activeSubMenu === name ? <IoIosArrowDown style={{ color: 'var(--black)', fontSize: '14px' }} /> : <IoIosArrowForward style={{ color: 'var(--black)', fontSize: '14px' }} />)}
          </ListItemButton>
        </ListItem>
        {SUBMENU && activeSubMenu === name &&
          <List sx={{ px: 1, py: 0 }}>
            {renderSubMenu(subMenus)}
          </List>
        }
      </List>
    );
  };

  return (

    <Box sx={{ transition: 'width 0.3s ease-in-out', height: '100vh' }}>
      <List sx={{ margin: '10px auto 0', display: 'flex', justifyContent: 'center' }}>
        {drawerStatus ?
          <Typography className='logo'>Home <span style={{ color: 'var(--primary)' }}>Hancer</span></Typography> :
          <Typography className='logo'>H<span style={{ color: 'var(--primary)' }}>H</span></Typography>
        }
        {/* {drawerStatus ?
          <img src={Logo} style={{ width: '130px' }} alt='' /> :
          <img src={smallLogo} style={{ width: '40px' }} alt='' />
        } */}

      </List>
      <Stack justifyContent={'space-between'} height={'calc(100% - 70px)'}>
        <Box marginTop={3}>
          {SidebarList.map((sidebarItem, index) => {
            const permission = checkPermission(
              sidebarItem.PermissionStatus,
              localStorage.getItem("ROLE")
            );
            if (!permission) return null;

            const active = isActive(sidebarItem.path, sidebarItem.subPath);
            return (
              <div key={index}>
                {renderListItems(sidebarItem)}
              </div>
            )
          })}
        </Box>
      </Stack>
    </Box>
  )
}

export default Sidebar