import React from 'react';
import { Typography } from '@mui/material';

const ErrorMessage = ({ message }) => {
  if (!message) return null;
  return (
    <Typography fontSize={'13px'} sx={{ color: 'red' }} mt={'2px'}>
      {message}
    </Typography>
  );
};

export default ErrorMessage;
