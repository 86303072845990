import React, { useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../../helper/Icons';
import { Button } from '..';
import { useMessage } from '../Message/MessageContext';
import { useNavigate } from 'react-router-dom';
import { clearData } from '../../../helper/indexDB';

const ActionModal = ({ handleClose, modalOpen }) => {
  const [loading, setLoading] = useState(false);
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const handleLogout = async () => {
    setLoading(true);
    localStorage.removeItem('token');
    localStorage.removeItem('ROLE');
    await clearData()
    navigate('/');
    showMessage('success', 'Logout successfully');
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Logout
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: '400px' }}>
          <Typography fontSize={'18px'}>Are you sure you want to logout?</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName={'No'} size='small' color='white' onClick={handleClose} />
        <Button buttonName={'Yes'} size='small' onClick={handleLogout} disabled={loading} />
      </DialogActions>
    </>
  );
};

export default ActionModal;
