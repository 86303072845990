import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function UploadMedia({ files, setFiles, setErrorMessage }) {
  const checkMediaDimensions = (file) => {
    return new Promise((resolve, reject) => {
      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          resolve({
            width: video.videoWidth,
            height: video.videoHeight
          });
        };

        video.onerror = () => {
          reject(new Error('Failed to load video'));
        };

        video.src = URL.createObjectURL(file);
      } else if (file.type === 'image/gif') {
        const img = new Image();
        img.onload = () => {
          window.URL.revokeObjectURL(img.src);
          resolve({
            width: img.width,
            height: img.height
          });
        };

        img.onerror = () => {
          reject(new Error('Failed to load GIF'));
        };

        img.src = URL.createObjectURL(file);
      } else {
        reject(new Error('Unsupported file type'));
      }
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size <= 5 * 1024 * 1024) { // 5 MB in bytes
        try {
          const dimensions = await checkMediaDimensions(selectedFile);
          if (dimensions.width === 648 && dimensions.height === 1152) {
            setFiles(selectedFile);
            setErrorMessage('');
          } else {
            setErrorMessage('Media dimensions must be 648×1152');
          }
        } catch (error) {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage('File size exceeds 5 MB');
      }
    }
  };

  const handleRemoveFile = () => {
    setFiles(null);
  };

  const handleUploadClick = () => {
    console.log('File to upload:', files);
    // Upload logic goes here
  };

  return (
    <Box>
      <label htmlFor="raised-button-file" onClick={handleUploadClick} style={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            height: '70px',
            border: '1px dashed #c4c4c4',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f8f8f8',
            fontSize: '14px',
            fontWeight: '600',
            cursor: 'pointer',
            padding: '0 10px'
          }}
        >
          Resolution: 648×1152 (Max. Allowed file size is 5 MB)
          <input
            accept="video/*,image/gif"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />
        </Box>
      </label>

      {/* Thumbnail */}
      {files && (
        <Stack
          gap={'10px'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          style={{ marginTop: '10px' }}
        >
          <Box
            sx={{
              position: 'relative',
              border: '1px solid #e3e3e3',
              borderRadius: '10px'
            }}
          >
            <IconButton
              style={{ position: 'absolute', right: 0, top: 0, zIndex: '9999' }}
              onClick={handleRemoveFile}
            >
              <DeleteIcon sx={{ fontSize: '16px' }} />
            </IconButton>
            {files.type.startsWith('video/') ? (
              <video
                src={typeof files === 'string' && files ? files : URL.createObjectURL(files)}
                alt='preview'
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  objectFit: 'cover'
                }}
                autoPlay
                muted
                loop
              />
            ) : (
              <img
                src={typeof files === 'string' && files ? files : URL.createObjectURL(files)}
                alt='preview'
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                  objectFit: 'cover'
                }}
              />
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
}

export default UploadMedia;