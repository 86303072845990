import { Box, Checkbox, Stack, Typography } from '@mui/material'
import { Button, Input } from '../../components/Common'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { useSendLoginOtpMutation, useVerifyLoginOtpMutation } from '../../services/Admin';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { addData } from '../../helper/indexDB';

const Login = () => {
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [sendLoginOtp] = useSendLoginOtpMutation();
  const [verifyLoginOtp] = useVerifyLoginOtpMutation();

  const handleSendOtp = async () => {
    try {
      let response;
      const sendOtpData = {
        email: email
      }

      response = await sendLoginOtp(sendOtpData);
      const { status, message } = response?.data || {};

      if (status) {
        showMessage('success', message);
        setShowOtp(true)
      } else {
        showMessage('error', message);
      }
    } catch (error) {
      showMessage('error', 'An error occurred while processing your request.');
    } finally {
      console.log('finally');
    }
  }

  const handleVerifyOtp = async () => {
    try {
      let response;
      const verifyOtpData = {
        email: email,
        otp: otp
      }

      response = await verifyLoginOtp(verifyOtpData);
      const { status, message, data } = response?.data || {};

      if (status) {
        localStorage.setItem('token', data?.token);
        localStorage.setItem('ROLE', data?.type);
        await addData({ value: data })
        if (data?.type === 1) {
          navigate('/dashboard');
        } else {
          navigate('/user');
        }
        showMessage('success', message);
      } else {
        showMessage('error', message);
      }
    } catch (error) {
      showMessage('error', 'An error occurred while processing your request.');
    } finally {
      console.log('finally');
    }
  }
  return (
    <Box className='d_flex' sx={{ width: '-webkit-fill-available', height: '100vh' }}>
      <Stack className='d_flex w_100 h_100' sx={{ position: 'relative' }}>
        <Stack gap={1} width={{ xs: '94%', sm: '400px' }} >
          <Typography className='logo'>Home <span style={{ color: 'var(--primary)' }}>Hancer</span></Typography>
          {/* <img src={Logo} style={{ width: '150px', position: 'absolute', top: '20px', left: '20px' }} alt='' /> */}
          <Box className='border' sx={{ display: 'flex', alignItems: 'center', padding: { xs: '25px 20px', sm: '40px 30px' }, borderRadius: '10px', backgroundColor: 'white.main' }}>
            <Stack gap={{ xs: 1 }} width={{ xs: '100%' }} alignItems={'start'}>
              <Typography fontWeight={'600'} textAlign={'center'} mb={1}>Log In to your account</Typography>
              <Stack gap={{ xs: 2, sm: 2 }} width={'100%'}>
                <Input placeholder='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                {showOtp && <div className='otp_input'>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>}
                <Box mt={1}>
                  {showOtp ?
                    <Button buttonName={'verify Otp'} onClick={handleVerifyOtp} />
                    : <Button buttonName={'Send Otp'} onClick={handleSendOtp} />
                  }
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>

  )
}

export default Login