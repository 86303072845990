import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { BackButton, Button, Heading, Input, Select, UploadImage, ErrorMessage } from '../../components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddNewServicesMutation, useUpdateServicesMutation } from '../../services/Services';
import { useGetserviceDepartmentDropdownQuery } from '../../services/ServiceDepartment';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { mostBookServide } from '../../helper';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useGetsubServiceDropdownQuery } from '../../services/SubServices';

const AddService = () => {
    const { showMessage } = useMessage();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetserviceDepartmentDropdownQuery();
    const serviceList = data?.data;
    const [addNewService] = useAddNewServicesMutation();
    const [updateService] = useUpdateServicesMutation();
    const [files, setFiles] = useState([]);
    const [selectService, setSelectService] = useState('');
    const { data: subService, error: subServiceError, isLoading: subServiceLoading } = useGetsubServiceDropdownQuery(selectService, { skip: !selectService });
    const subServiceList = subService?.data;
    const [selectSubService, setSelectSubService] = useState('');
    const [selectMostBook, setSelectMostBook] = useState('');
    const [descValue, setDescValue] = useState('');
    const [fulldescValue, setFullDescValue] = useState('');
    const [fileErrorMessage, setFileErrorMessage] = useState('');

    const [formValues, setFormValues] = useState({
        serviceCategory: '',
        subService: '',
        serviceName: '',
        servicePrice: '',
        offer: '',
        id: state?.id || null
    });

    const [formErrors, setFormErrors] = useState({
        serviceCategory: '',
        subService: '',
        serviceName: '',
        servicePrice: '',
        // offer: '',
        mostBook: '',
        serviceDesc: '',
        fullDesc: '',
    });

    const handleChange = (e) => {
        setSelectService(e.target.value);
    };
    const handleSubServiceChange = (e) => {
        setSelectSubService(e.target.value);
    };
    const handleMostBookChange = (e) => {
        setSelectMostBook(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleFormSubmit = async () => {
        // Reset error messages
        setFormErrors({
            serviceCategory: '',
            subService: '',
            serviceName: '',
            servicePrice: '',
            mostBook: '',
            // offer: '',
            serviceDesc: '',
            fullDesc: '',
        });
        setFileErrorMessage('');

        // Validation for mandatory fields
        let hasError = false;
        if (files.length === 0) {
            setFileErrorMessage('Please select a file to upload.');
            hasError = true;
        }
        if (!selectService) {
            setFormErrors(prev => ({ ...prev, serviceCategory: 'Service Category is a mandatory field.' }));
            hasError = true;
        }
        if (!selectSubService) {
            setFormErrors(prev => ({ ...prev, subService: 'Sub Service is a mandatory field.' }));
            hasError = true;
        }
        if (!formValues.serviceName) {
            setFormErrors(prev => ({ ...prev, serviceName: 'Service Name is a mandatory field.' }));
            hasError = true;
        }
        if (!formValues.servicePrice) {
            setFormErrors(prev => ({ ...prev, servicePrice: 'Service Price is a mandatory field.' }));
            hasError = true;
        }
        if (!selectMostBook) {
            setFormErrors(prev => ({ ...prev, mostBook: 'Most Book is a mandatory field.' }));
            hasError = true;
        }
        // if (!formValues.offer) {
        //     setFormErrors(prev => ({ ...prev, offer: 'Offer is a mandatory field.' }));
        //     hasError = true;
        // }
        if (!descValue) {
            setFormErrors(prev => ({ ...prev, serviceDesc: 'Service Description is a mandatory field.' }));
            hasError = true;
        }
        if (!fulldescValue) {
            setFormErrors(prev => ({ ...prev, fullDesc: 'Full Description is a mandatory field.' }));
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const formData = new FormData();
        formData.append('serviceName', formValues.serviceName);
        formData.append('image', files);
        formData.append('serviceDepartmentId', selectService);
        formData.append('subServiceId', selectSubService);
        formData.append('mostBooked', selectMostBook);
        formData.append('servicePrice', formValues.servicePrice);
        formData.append('serviceDescription', descValue);
        formData.append('fullDescription', fulldescValue);
        formData.append('offer', formValues.offer);
        try {
            let response;

            if (state) {
                response = await updateService({ formData, id: state.id });
            } else {
                response = await addNewService(formData);
            }

            const { status, message } = response?.data || {};
            if (status) {
                navigate('/services');
                showMessage('success', message);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            console.error('Failed to save the service:', error);
        }
    };

    useEffect(() => {
        if (state) {
            setFormValues({
                serviceName: state?.data?.serviceName,
                subService: state?.data?.subserviceName,
                servicePrice: state?.data?.servicePrice,
                offer: state?.data?.offer,
                id: state.id
            });
            setSelectSubService(state?.data?.subServiceId)
            setFiles(state?.data?.image);
            setSelectService(state?.data?.serviceDepartmentId);
            setSelectMostBook(state?.data?.mostBooked);
            setDescValue(state?.data?.serviceDescription);
            setFullDescValue(state?.data?.fullDescription);
        }
    }, [state]);

    return (
        <>
            <Stack flexDirection={'row'} gap={1} mb={1}>
                <BackButton />
                <Heading head={formValues.id ? 'Edit Service' : 'Add Service'} />
            </Stack>
            <Box className='card_container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Service Image</Typography>
                        <UploadImage files={files} setFiles={setFiles} />
                        <ErrorMessage message={fileErrorMessage} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select
                            selectList={isLoading ? [] : serviceList}
                            labelinput='Service Category'
                            name='serviceCategory'
                            value={selectService}
                            onChange={handleChange}
                        />
                        <ErrorMessage message={formErrors.serviceCategory} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select
                            selectList={subServiceLoading ? [] : subServiceList}
                            labelinput='Sub Service'
                            name='subService'
                            value={selectSubService}
                            onChange={handleSubServiceChange}
                        />
                        <ErrorMessage message={formErrors.subService} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Select
                            selectList={mostBookServide}
                            labelinput='Most Book Service'
                            name='serviceCategory'
                            value={selectMostBook}
                            onChange={handleMostBookChange}
                        />
                        <ErrorMessage message={formErrors.mostBook} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Input
                            labelinput='Service Name'
                            name='serviceName'
                            value={formValues.serviceName}
                            onChange={handleInputChange}
                        />
                        <ErrorMessage message={formErrors.serviceName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Input
                            labelinput='Service Price'
                            name='servicePrice'
                            value={formValues.servicePrice}
                            onChange={handleInputChange}
                        />
                        <ErrorMessage message={formErrors.servicePrice} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Input
                            labelinput='Offer'
                            name='offer'
                            type='number'
                            value={formValues.offer}
                            onChange={handleInputChange}
                        />
                        <ErrorMessage message={formErrors.offer} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Service Description</Typography>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: [
                                        "|",
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "|",
                                        "bulletedList",
                                    ],
                                    shouldNotGroupWhenFull: false,
                                },
                            }}
                            data={descValue || ""}
                            onChange={(event, editor) => {
                                setDescValue(editor.getData());
                            }}
                        />
                        <ErrorMessage message={formErrors.serviceDesc} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Full Description</Typography>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: [
                                        "|",
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "|",
                                        "bulletedList",
                                    ],
                                    shouldNotGroupWhenFull: false,
                                },
                            }}
                            data={fulldescValue || ""}
                            onChange={(event, editor) => {
                                setFullDescValue(editor.getData());
                            }}
                        />
                        <ErrorMessage message={formErrors.fullDesc} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} gap={1}>
                            <Button buttonName='Save' onClick={handleFormSubmit} />
                            <Button buttonName='Cancel' color='white' onClick={() => navigate('/services')} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddService;
