import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils'

export const settingsApis = createApi({
  reducerPath: 'settingsApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['setting'],
  endpoints: (builder) => ({
    getsettingsList: builder.query({
      query: () => `settingsList`,
      providesTags: ['setting'],
    }),
    updateSetting: builder.mutation({
      query: (body) => {
        return { url: `radiusUpdate`, body, method: "PUT" }
      },
      providesTags: ['setting'],
    }),
  }),
})

export const { useGetsettingsListQuery, useUpdateSettingMutation } = settingsApis