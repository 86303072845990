import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../../helper/Icons';
import { Button, Input } from '../../Common';

const ChangeStatus = ({ handleClose, modalOpen, onSubmit }) => {
    const [reason, setReason] = useState('');

    const handleClick = () => {
        onSubmit(reason);
    };

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.head}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        {modalOpen.isBlocking && (
                            <Input
                                labelinput={`Reason for ${modalOpen.head}`}
                                multiline
                                rows={2}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={'Save'} size='small' onClick={handleClick} />
            </DialogActions>
        </>
    );
};

export default ChangeStatus;