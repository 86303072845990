import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const subServiceApis = createApi({
  reducerPath: 'subServiceApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['SubService'],
  endpoints: (builder) => ({
    getSubServiceList: builder.query({
      query: ({ page, search }) => `subServiceList?page=${page}&search=${search}`,
      providesTags: ['SubService'],
    }),
    getsubServiceDropdown: builder.query({
      query: (id) => `subServiceDropdown/${id}`,
      providesTags: ['SubService'],
    }),
    addNewSubService: builder.mutation({
      query: (body) => {
        return { url: `addSubService`, body, method: "POST" }
      },
      invalidatesTags: ['SubService'],
    }),
    updateSubService: builder.mutation({
      query: (body) => {
        const { id, ...rest } = body;
        return { url: `updateSubService/${id}`, body: rest, method: "PUT" }
      },
      invalidatesTags: ['SubService'],
    }),
    deleteSubService: builder.mutation({
      query: (id) => {
        return { url: `deleteSubService/${id}`, method: "DELETE" }
      },
      invalidatesTags: ['SubService'],
    }),
    statusChangeSubService: builder.mutation({
      query: (body) => {
        const { id } = body;
        return { url: `statusChangeSubService/${id}`, method: "PUT" }
      },
      invalidatesTags: ['SubService'],
    }),
  }),
})

export const { useGetSubServiceListQuery, useGetsubServiceDropdownQuery, useAddNewSubServiceMutation,
  useDeleteSubServiceMutation, useUpdateSubServiceMutation, useStatusChangeSubServiceMutation } = subServiceApis