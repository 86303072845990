import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, TableLoader } from "../../components/Common/Table";
import { MdMoreVert } from "react-icons/md";
import { dummy } from "../../helper/Constant";
import { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AiOutlineEdit } from "react-icons/ai";
import { useDeleteBannerMutation, useGetBannerListQuery } from "../../services/banner";
import { HiOutlineTrash } from "react-icons/hi";

export default function Banner() {
    const [page, setPage] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' });
    const { data, error, isLoading } = useGetBannerListQuery({ page: page + 1 });
    const [deleteBanner] = useDeleteBannerMutation()
    const bannerList = data?.data;

    return (
        <>
            <Stack>
                <Stack my={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Heading head={'Banner'} />
                    <Button buttonName='Add Banner' onClick={() => setIsModalOpen({ open: true, currentComponent: "addBanner", isEdit: false, id: '', data: '' })} />
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Photo</TableCell>
                            <TableCell>Banner Type</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='4' />) :
                            bannerList && bannerList.length > 0 ?
                                (bannerList?.map((ele, i) => {
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                <img src={ele.image ? ele?.image : dummy} alt="banner_image" width={'300px'} />
                                            </TableCell>
                                            <TableCell>{ele?.type}</TableCell>
                                            <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "addBanner", isEdit: true, id: ele?.id, data: ele })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Banner", para: "Are you sure you want to delete this banner?", id: ele?.id, delete: deleteBanner })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }} >Delete</Box></Stack>
                                                            </DropdownMenu.Item>
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan='4' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {bannerList && bannerList.length > 0 ? <Pagination totalData={data?.total || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' })}
            />
        </>
    );
}
