import { Box, Grid, Stack, Typography } from "@mui/material";
import { Heading } from "../../components/Common";
import { MdWorkOutline } from "react-icons/md";
import { useGetDashboardDataQuery } from "../../services/Dashboard";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Dashboard() {
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } = useGetDashboardDataQuery();
  const dashboardData = data?.date;

  useEffect(() => {
    refetch(); // Calls the API when the component is mounted
  }, [refetch]);

  return (
    <>
      <Stack mb={1}>
        <Heading head={'Dashboard'} />
      </Stack>
      <Stack py={1}>
        <Heading smallHead={"Today's Job"} />
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={6}>
            <Box className='card_container' sx={{ cursor: 'pointer' }} onClick={() => navigate('/jobs', { state: 'newJob' })}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.newJob}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>NEW JOBS</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className='card_container' sx={{ cursor: 'pointer' }} onClick={() => navigate('/jobs', { state: 'startJob' })}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.jobInProgress}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>JOBS IN PROCESS</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Stack py={1}>
        <Heading smallHead={"Monthly's Job"} />
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Box className='card_container' sx={{ cursor: 'pointer' }} onClick={() => navigate('/non-verify-job', { state: 'completedJob' })}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.completedJob}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>COMPELETED JOBS</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box className='card_container' sx={{ cursor: 'pointer' }} onClick={() => navigate('/verify-job', { state: 'verifiedJob' })}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.verifiedJob}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>VERIFIED JOBS</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box className='card_container' sx={{ cursor: 'pointer' }} onClick={() => navigate('/jobs', { state: 'cancellJob' })}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.cancellJob}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>CANCELLED JOBS</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Stack py={1}>
        <Heading smallHead={"Monthly Revenue Jobs"} />
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={6}>
            <Box className='card_container'>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.totalRevenue}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>TOTAL JOBS REVNUE</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className='card_container'>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.collectRevenue}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>COLLECTED REVENUE</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Stack py={1}>
        <Heading smallHead={"All Time"} />
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={12} sm={6}>
            <Box className='card_container' sx={{ cursor: 'pointer' }} onClick={() => navigate('/jobs', { state: 'pendingJob' })}>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.pendingJobs}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>PENDING JOBS</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className='card_container'>
              <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '10px', sm: 2 }}>
                <MdWorkOutline className="dashboard_icon" />
                <Stack gap={'5px'}>
                  <Typography fontSize={{ xs: '20px', sm: '28px' }} fontWeight={'600'} lineHeight={1}>{dashboardData?.pendingRevenue}</Typography>
                  <Typography fontSize={{ xs: '13px', sm: '16px' }} lineHeight={1} fontWeight={'300'}>PENDING REVNUE</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
