import { Stack } from '@mui/material'
import React from 'react'

const MenuButton = ({ icon, width, height }) => {
  return (
    <Stack className='d_flex border_radius border box_shadow' sx={{ cursor: 'pointer', width: width ? width : { xs: '35px', sm: '35px' }, height: height ? height : { xs: '35px', sm: '35px' }, backgroundColor: 'white.main' }}>
      {icon}
    </Stack>
  )
}

export default MenuButton