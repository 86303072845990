import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const bannerApis = createApi({
    reducerPath: 'bannerApis',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Banner'],
    endpoints: (builder) => ({
        getBannerList: builder.query({
            query: ({ page }) => `bannerList?page=${page}`,
            providesTags: ['Banner'],
        }),
        addNewBanner: builder.mutation({
            query: (body) => {
                return { url: `createBanner`, body, method: "POST" }
            },
            invalidatesTags: ['Banner'],
        }),
        updateBanner: builder.mutation({
            query: (body) => {
                const { id, formData } = body;
                return { url: `updateBanner/${id}`, body: formData, method: "PUT" }
            },
            invalidatesTags: ['Banner'],
        }),
        deleteBanner: builder.mutation({
            query: (id) => {
                return { url: `deleteBanner/${id}`, method: "DELETE" }
            },
            invalidatesTags: ['Banner'],
        }),
    }),
})

export const { useGetBannerListQuery, useAddNewBannerMutation, useDeleteBannerMutation, useUpdateBannerMutation } = bannerApis