import Dialog from '@mui/material/Dialog';
import { ChangePassword, ActionModal, Logout, ChangeStatus } from './modals';
import AddBanner from '../../pages/Banner/AddBanner';
import AddThought from '../../pages/Thought/AddThought';
import AddServiceDept from '../../pages/ServiceDepartment/AddServiceDept';
import AddSubService from '../../pages/SubService/AddSubService';
import AddAddress from '../../pages/Address/AddAddress';
import AddUser from '../../pages/User/AddUser';
import AddReview from '../../pages/Review/AddReview';
import ViewUser from '../../pages/User/ViewUser';
import AddAdminReview from '../../pages/VerifyJob/AddAdminReview';

export default function CommonModal({ handleClose, modalOpen, onSubmit, onAddressAdded }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >
      {modalOpen.currentComponent === 'changePassword' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'actionModal' && (
        <ActionModal handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addBanner' && (
        <AddBanner handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addThought' && (
        <AddThought handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addServiceDept' && (
        <AddServiceDept handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addSubService' && (
        <AddSubService handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addClient' && (
        <AddUser handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'viewClient' && (
        <ViewUser handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'addAddress' && (
        <AddAddress handleClose={handleClose} modalOpen={modalOpen} onAddressAdded={onAddressAdded} />
      )}
      {modalOpen.currentComponent === 'addReview' && (
        <AddReview handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === 'changeStatus' && (
        <ChangeStatus handleClose={handleClose} modalOpen={modalOpen} onSubmit={onSubmit} />
      )}

      {modalOpen.currentComponent === 'addAdminReview' && (
        <AddAdminReview handleClose={handleClose} modalOpen={modalOpen} />
      )}
    </Dialog>
  );
}
