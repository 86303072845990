import axios from "axios";
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from "./utils";


export const getFresherPerferenceLists = async (values) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}addSubscription`, values
        );
        return response.data;
    } catch (error) {
        return error;
    }
}


export const notificationApis = createApi({
    reducerPath: 'notificationApis',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Notification'],
    endpoints: (builder) => ({
        getAllNotification: builder.query({
            query: () => `getallNotification`,
            providesTags: ['Notification'],
        }),
        readAllNotification: builder.mutation({
            query: () => {
                return { url: `ReadAllNotification`, method: "POST" }
            },
            invalidatesTags: ['Notification'],
        }),
        readNotification: builder.mutation({
            query: (id) => {
                return { url: `readNotification/${id}`, method: "POST" }
            },
            invalidatesTags: ['Notification'],
        }),
    }),
})

export const { useGetAllNotificationQuery, useReadAllNotificationMutation, useReadNotificationMutation } = notificationApis