import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, Search, TableLoader } from "../../components/Common/Table";
import { MdMoreVert } from "react-icons/md";
import { dummy } from "../../helper/Constant";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { useDeleteServiceDeptMutation, useGetServiceDeptListQuery, useStatusChangeServiceDepartmentMutation } from "../../services/ServiceDepartment";
import useDebounce from "../../helper/utils";
import { useMessage } from "../../components/Common/Message/MessageContext";

export default function ServiceDepartment() {
    const { showMessage } = useMessage();
    const [page, setPage] = useState(0)
    const [searchData, setSearchData] = useState('')
    const search = useDebounce(searchData, 500)
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '', });
    const { data, error, isLoading } = useGetServiceDeptListQuery({ page: page + 1, search });
    const [deleteServiceDept] = useDeleteServiceDeptMutation();
    const serviceDeptList = data?.data;

    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [changeStatus] = useStatusChangeServiceDepartmentMutation();

    const handleStatusChange = async (adminId, adminStatus) => {
        setSelectedAdmin({ id: adminId, status: adminStatus });
        setIsModalOpen({
            open: true,
            currentComponent: "changeStatus",
            head: adminStatus === 1 ? "Block Service Department" : "Unblock Service Department",
            para: adminStatus === 1
                ? "Are you sure you want to Block this service department?"
                : "Are you sure you want to Unblock this service department?"
        });
    };

    const handleStatusChangeSubmit = async () => {

        try {
            const response = await changeStatus({
                id: selectedAdmin.id,
            });

            const { status, message } = response.data || {};

            if (status) {
                showMessage('success', message);
                // Refresh the admin list or update the local state
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while changing status');
        }

        setIsModalOpen({ open: false, currentComponent: "", head: '' });
        setSelectedAdmin(null);
    };

    const userType = localStorage.getItem("ROLE");

    return (
        <>
            <Stack>
                <Heading head={'Service Department'} />
                <Stack my={1} flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={1} justifyContent={'space-between'} alignItems={{ xs: 'flex-end', sm: 'center' }}>
                    <Search setSearch={setSearchData} search={searchData} setPage={setPage} />
                    {userType === '2' ? '' :
                        <Button buttonName='Add Service Department' onClick={() => setIsModalOpen({ open: true, currentComponent: "addServiceDept", isEdit: false })} />}
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Photo</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Banner</TableCell>
                            {userType === '2' ? '' : <TableCell>Block/Unblock</TableCell>}
                            {userType === '2' ? '' : <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan={userType === '2' ? '4' : '6'} />) :
                            serviceDeptList && serviceDeptList?.length > 0 ?
                                (serviceDeptList?.map((ele, i) => {
                                    const { serviceName, image, status, id, imageData } = ele
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                <img src={image ? image : dummy} alt="banner_image" width={'100px'} height='100px' style={{ objectFit: 'cover' }} />
                                            </TableCell>
                                            <TableCell>{serviceName}</TableCell>
                                            <TableCell>
                                                <Stack gap={1} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'}>
                                                    {imageData?.map((item, i) => {
                                                        return (
                                                            <img key={i} src={item?.bannerImage ? item?.bannerImage : dummy} alt="banner_image" width={'200px'} height='100px' style={{ objectFit: 'cover' }} />
                                                        )
                                                    })}
                                                </Stack>
                                            </TableCell>
                                            {userType === '2' ? '' : <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Switch
                                                    checked={status === 1}
                                                    onClick={() => handleStatusChange(id, status)}
                                                />
                                            </TableCell>}
                                            {userType === '2' ? '' : <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "addServiceDept", isEdit: true, id: ele?.id, data: ele })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            {/* <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Service Department", para: "Are you sure you want to delete this service department?", id: ele?.id, delete: deleteServiceDept })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Delete</Box></Stack>
                                                            </DropdownMenu.Item> */}
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan={userType === '2' ? '4' : '6'} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {serviceDeptList && serviceDeptList?.length > 0 ? <Pagination totalData={data?.totalData || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                onSubmit={handleStatusChangeSubmit}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' })}
            />
        </>
    );
}
