import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { bannerApis } from './services/banner'
import { thoughtApis } from './services/thought'
import { employeeApis } from './services/Employee'
import { clientApis } from './services/Client'
import { serviceDeptApis } from './services/ServiceDepartment'
import { subServiceApis } from './services/SubServices'
import { settingsApis } from './services/settings'
import { adminApis } from './services/Admin'
import { servicesApis } from './services/Services'
import { slotApis } from './services/Slot'
import { locationApis } from './services/Location'
import { jobsApis } from './services/Job'
import { rattingApis } from './services/Rating'
import { dashboardApis } from './services/Dashboard'
import { notificationApis } from './services/Notification'
import { addressApis } from './services/Address'
import { reportApis } from './services/Report'

export const store = configureStore({
    reducer: {
        [bannerApis.reducerPath]: bannerApis.reducer,
        [thoughtApis.reducerPath]: thoughtApis.reducer,
        [employeeApis.reducerPath]: employeeApis.reducer,
        [clientApis.reducerPath]: clientApis.reducer,
        [serviceDeptApis.reducerPath]: serviceDeptApis.reducer,
        [subServiceApis.reducerPath]: subServiceApis.reducer,
        [settingsApis.reducerPath]: settingsApis.reducer,
        [adminApis.reducerPath]: adminApis.reducer,
        [servicesApis.reducerPath]: servicesApis.reducer,
        [slotApis.reducerPath]: slotApis.reducer,
        [locationApis.reducerPath]: locationApis.reducer,
        [jobsApis.reducerPath]: jobsApis.reducer,
        [rattingApis.reducerPath]: rattingApis.reducer,
        [dashboardApis.reducerPath]: dashboardApis.reducer,
        [notificationApis.reducerPath]: notificationApis.reducer,
        [addressApis.reducerPath]: addressApis.reducer,
        [reportApis.reducerPath]: reportApis.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(bannerApis.middleware)
            .concat(thoughtApis.middleware)
            .concat(employeeApis.middleware)
            .concat(serviceDeptApis.middleware)
            .concat(clientApis.middleware)
            .concat(subServiceApis.middleware)
            .concat(settingsApis.middleware)
            .concat(adminApis.middleware)
            .concat(servicesApis.middleware)
            .concat(slotApis.middleware)
            .concat(locationApis.middleware)
            .concat(jobsApis.middleware)
            .concat(rattingApis.middleware)
            .concat(dashboardApis.middleware)
            .concat(notificationApis.middleware)
            .concat(addressApis.middleware)
            .concat(reportApis.middleware)
})

setupListeners(store.dispatch)