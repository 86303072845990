import React from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';
import { useMessage } from './MessageContext';

function SlideTransition(props) {
  return <Slide {...props} direction="top" />;
}


const Message = () => {
  const { isMessageOpen, closeMessage } = useMessage();

  return (
    <Snackbar
      open={isMessageOpen.open}
      autoHideDuration={4000}
      onClose={closeMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Alert onClose={closeMessage} severity={isMessageOpen.currentSeverity}>
        {isMessageOpen.message}
      </Alert>
    </Snackbar>
  );
};

export default Message;
