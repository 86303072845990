import { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CgMenuLeft, FaUserLarge, GoBell, PiBellSimpleRinging } from '../../helper/Icons';
import { MenuButton, Modal } from '../Common';
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { IoIosArrowDown } from 'react-icons/io';
import { MdOutlineLogout, MdOutlineManageAccounts, MdPassword } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAdminDetailQuery } from '../../services/Admin';
import { getAllData } from '../../helper/indexDB';
import { useUserNotificationSubscribeHook } from "../../notification";
import { useGetAllNotificationQuery, useReadAllNotificationMutation, useReadNotificationMutation } from '../../services/Notification';
import moment from 'moment/moment';


const Header = ({ handleDrawerToggle, handleDrawerCloseToggleWeb }) => {
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    image: '',
    name: '',
  });

  const { registerAndSubscribe } = useUserNotificationSubscribeHook();
  const { data: notification, isLoading: notificationLoading, error: notificationError, refetch } = useGetAllNotificationQuery();
  const notificationList = notification?.data
  const [readAllNotification] = useReadAllNotificationMutation();
  const [readNotification] = useReadNotificationMutation();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleAlertClick = () => { setIsAlertOpen(true) };
  const { data, error, isLoading } = useGetAdminDetailQuery(user, { skip: !user });

  useEffect(() => {
    if (data) {
      setProfileData({
        image: data?.profileImage,
        name: data?.name,
      });
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const storedData = await getAllData();
      setUser(storedData?.[0]?.value.id);
    };

    fetchData();
  }, []);

  const handelLogout = () => {
    navigate("/");
  };

  useEffect(() => {
    if (data && data?.data && data?.data?.id) {

      const payload = {
        userId: data?.data?.id,
      }
      registerAndSubscribe(payload);
    }
  }, [data]);


  const handleClickNotification = async (id) => {
    try {
      await readNotification(id)
      // console.log('Notification marked as read:', response);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const handleClickReadAllNotification = async () => {
    try {
      await readAllNotification()
      // console.log('Notifications marked as read:', response);
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '6px', sm: 1 }} justifyContent={'flex-start'}>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <MenuButton icon={<CgMenuLeft className='menu_icon' onClick={handleDrawerToggle} />} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <MenuButton icon={<CgMenuLeft className='menu_icon' onClick={handleDrawerCloseToggleWeb} />} />
          </Box>
        </Stack>
        <Box>
          <Stack flexDirection={'row'} gap={{ xs: '6px', sm: 1 }} className='d_flex'>
            <DropdownMenu.Root open={isAlertOpen} onOpenChange={(open) => { setIsAlertOpen(open); refetch() }}>
              <DropdownMenu.Trigger className='dropDown'>
                <Box position={'relative'}>
                  {notification?.count > 0 &&
                    (<Box sx={{ position: 'absolute', right: '5px', top: '1px', background: '#ff2e2e', padding: notification?.count ? '2px' : '', borderRadius: '50%', fontSize: '10px', lineHeight: '1', color: 'var(--white)', minWidth: notification?.count ? '14px' : '' }}>
                      {notification?.count}
                    </Box>)}
                  <MenuButton icon={<GoBell style={{ color: '#000', fontSize: '18px' }} />} />
                </Box>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className='dropDownContent' align='end' onClick={handleAlertClick}>
                <DropdownMenu.Item className='dropDownMenu' onClick={handleAlertClick}>
                  <Box sx={{ width: '300px', height: '300px' }}>
                    <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={'10px'}>
                      <Typography fontSize={'13px'} fontWeight={700}>All Notification</Typography>
                      <Typography fontSize={'11px'} fontWeight={400} onClick={handleClickReadAllNotification}><Link>Mark all as read</Link></Typography>
                    </Stack>
                    <Box maxHeight={'calc(100% - 30px)'} height={'100%'} sx={{ overflow: 'hidden' }}>
                      <Stack gap={1} sx={{ height: '-webkit-fill-available', overflow: 'scroll' }}>
                        {notificationList?.slice()?.reverse()?.map((data, i) => {
                          const { title, description, createdAt, id, isRead } = data;
                          const timeAgo = moment(createdAt).fromNow();
                          return (
                            <Stack onClick={() => handleClickNotification(id)} key={i} flexDirection={'row'} gap={1} alignItems={'center'} sx={{ background: isRead === 1 ? '#f1f1f1' : 'var(--primaryLight)', padding: 1 }}>
                              <Box>
                                <PiBellSimpleRinging style={{ fontSize: '22px' }} />
                              </Box>
                              <Box sx={{ width: '-webkit-fill-available' }}>
                                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={'2px'}>
                                  <Typography fontSize={'12px'} fontWeight={600}>{title}</Typography>
                                  <Typography fontSize={'10px'} fontWeight={400}>{timeAgo}</Typography>
                                </Stack>
                                <Typography fontSize={'10px'} fontWeight={400} color={'#a1a1a1'}>{description}</Typography>
                              </Box>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Box>
                  </Box>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="dropDown">
                <Stack
                  flexDirection={"row"}
                  gap={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className='box_shadow'
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    background: "var(--white)",
                    p: "6px 10px",
                    borderRadius: "6px",
                  }}
                >
                  <FaUserLarge
                    style={{
                      borderRadius: "50%",
                      color: "var(--primary)",
                      fontSize: "20px",
                    }}
                  />
                  <Stack sx={{ display: { xs: "block", sm: "block" } }}>
                    <Typography fontSize={"16px"} lineHeight={1.4}>{data?.data?.name}</Typography>
                  </Stack>
                  <IoIosArrowDown />
                </Stack>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className="dropDownContent" align="end">
                <DropdownMenu.Item
                  className="dropDownMenu"
                  onClick={() => navigate("/profile")}
                >
                  <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <MdOutlineManageAccounts style={{ fontSize: "20px" }} />
                    <Box sx={{ fontSize: "16px" }}>Profile</Box>
                  </Stack>
                </DropdownMenu.Item>
                {/* <DropdownMenu.Item
                  className="dropDownMenu"
                  onClick={() => {
                    setIsModalOpen({
                      open: true,
                      currentComponent: "changePassword",
                    });
                  }}
                >
                  <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <MdPassword style={{ fontSize: "20px" }} />
                    <Box sx={{ fontSize: "16px" }}>Change Password</Box>
                  </Stack>
                </DropdownMenu.Item> */}
                <DropdownMenu.Item
                  className="dropDownMenu"
                  onClick={() => { setIsModalOpen({ open: true, currentComponent: "logout" }); }}                >
                  <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                    <MdOutlineLogout style={{ fontSize: "20px" }} />
                    <Box sx={{ fontSize: "16px" }}>Logout</Box>
                  </Stack>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Stack>
        </Box>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() =>
          setIsModalOpen({
            open: false,
            currentComponent: "",
            para: "",
            head: "",
          })
        }
      />
    </>
  )
}

export default Header