import React from 'react';
import { DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import { useGetClientDetailQuery } from '../../services/Client';
import { dummy } from '../../helper/Constant';

const ViewUser = ({ handleClose, modalOpen }) => {
    const { data, error, isLoading } = useGetClientDetailQuery(modalOpen?.id);
    const clientDetail = data?.data;

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                User Details
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Stack gap={'2px'}>
                            <img src={clientDetail?.image ? clientDetail?.image : dummy} alt="banner_image" width={'70px'} height='70px' style={{ objectFit: 'cover' }} />
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack gap={'2px'}>
                            <Typography color={'var(--text)'}>Name</Typography>
                            <Typography fontWeight={'500'}>{clientDetail?.name ? clientDetail?.name : '-'}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack gap={'2px'}>
                            <Typography color={'var(--text)'}>Mobile No.</Typography>
                            <Typography fontWeight={'500'}>{clientDetail?.mobile ? clientDetail?.mobile : '-'}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack gap={'2px'}>
                            <Typography color={'var(--text)'}>Email</Typography>
                            <Typography fontWeight={'500'}>{clientDetail?.email ? clientDetail?.email : '-'}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack gap={'2px'}>
                            <Typography color={'var(--text)'}>Address</Typography>
                            <Stack gap={1} mt={'5px'}>
                                {clientDetail?.address && clientDetail?.address.length > 0 ?
                                    (clientDetail?.address?.map((ele, i) => {
                                        const { house, building, address, landmark, city } = ele
                                        return (
                                            <Typography key={i} fontWeight={'500'}>{i + 1}. &nbsp; {house}, {building}, {address}, {landmark}, {city}</Typography>
                                        )
                                    })) : '-'
                                }


                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>

        </>
    );
};

export default ViewUser;
