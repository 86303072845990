import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Rating, Typography } from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import { Button, Input, ErrorMessage } from '../../components/Common';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { useAddAdminReviewMutation } from '../../services/Rating';

const AddAdminReview = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();

    const [message, setMessage] = useState('');
    const [ratting, setRatting] = useState('');

    const [messageErrorMessage, setMessageErrorMessage] = useState('');
    const [rattingErrorMessage, setRattingErrorMessage] = useState('');

    const [addAdminReview] = useAddAdminReviewMutation();

    const handleSubmit = async () => {
        // Reset error messages
        setMessageErrorMessage('');
        setRattingErrorMessage('');

        // Validation for mandatory fields
        let hasError = false;
        if (!message) {
            setMessageErrorMessage('Message is a mandatory field.');
            hasError = true;
        }

        if (!ratting) {
            setRattingErrorMessage('Ratting is a mandatory field.');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const formData = {
            ratting: ratting,
            jobId: modalOpen?.data,
            review: message,
        }

        try {
            let response = await addAdminReview(formData);

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
                modalOpen.reviewdone(true);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
            handleClose();
        }
    };

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Admin Review
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Ratting</Typography>
                        <Rating name="half-rating" defaultValue={2.5} precision={0.5} onChange={(event, newValue) => {
                            setRatting(newValue);
                        }} />
                        <ErrorMessage message={rattingErrorMessage} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Select selectList={clientLoading ? [] : clientlist} value={selectClient} labelinput='Select Client'
                            onChange={handleClientChange} />
                        <ErrorMessage message={userErrorMessage} />
                    </Grid> */}
                    <Grid item xs={12}>
                        <Input labelinput='Message' value={message} multiline rows={2} onChange={(e) => setMessage(e.target.value)} />
                        <ErrorMessage message={messageErrorMessage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    );
};

export default AddAdminReview;
