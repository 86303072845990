import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, Search, TableLoader } from "../../components/Common/Table";
import { MdMoreVert, MdOutlineRemoveRedEye } from "react-icons/md";
import { dummy } from "../../helper/Constant";
import { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { useGetClientListQuery, useDeleteClientMutation } from "../../services/Client";
import useDebounce from "../../helper/utils";

export default function User() {
    const [page, setPage] = useState(0)
    const [searchData, setSearchData] = useState('')
    const search = useDebounce(searchData, 500)
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '', });
    const { data, error, isLoading } = useGetClientListQuery({ page: page + 1, search });
    const [deleteClient] = useDeleteClientMutation();
    const clientList = data?.data;

    return (
        <>
            <Stack>
                <Heading head={'User'} />
                <Stack my={1} flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={1} justifyContent={'space-between'} alignItems={{ xs: 'flex-end', sm: 'center' }}>
                    <Search setSearch={setSearchData} search={searchData} setPage={setPage} />
                    <Button buttonName='Add User' onClick={() => setIsModalOpen({ open: true, currentComponent: "addClient", isEdit: false })} />
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Photo</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='6' />) :
                            clientList && clientList.length > 0 ?
                                (clientList?.map((ele, i) => {
                                    const { name, mobile, image, email } = ele;
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>
                                                <img src={image ? image : dummy} alt="banner_image" width={'70px'} height='70px' style={{ objectFit: 'cover' }} />
                                            </TableCell>
                                            <TableCell>{name ? name : '-'}</TableCell>
                                            <TableCell>{mobile ? mobile : '-'}</TableCell>
                                            <TableCell>{email ? email : '-'}</TableCell>
                                            <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "addClient", isEdit: true, id: ele?.id, data: ele })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "viewClient", id: ele?.id })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><MdOutlineRemoveRedEye style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>View</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            {/* <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Client", para: "Are you sure you want to delete this Client?", id: ele?.id, delete: deleteClient })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Delete</Box></Stack>
                                                            </DropdownMenu.Item> */}
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan='6' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {clientList && clientList.length > 0 ? <Pagination totalData={data?.total || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' })}
            />
        </>
    );
}
