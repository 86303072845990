import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils'

export const addressApis = createApi({
    reducerPath: 'addressApis',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Address'],
    endpoints: (builder) => ({
        addUserAddress: builder.mutation({
            query: (body) => {
                return { url: `addUserAddress`, body, method: "POST" }
            },
            // providesTags: ['Address'],
        }),
        addressDropdown: builder.mutation({
            query: (body) => {
                return { url: `addressDropdown`, body, method: "POST" }
            },
            // invalidatesTags: ['Address'],
        }),
        updateAddress: builder.mutation({
            query: ({ body, id, jobId }) => {
                return { url: `updateAddress/${id}/${jobId}`, body, method: "PUT" }
            },
            // invalidatesTags: ['Address'],
        }),
    }),
})

export const { useAddUserAddressMutation, useAddressDropdownMutation, useUpdateAddressMutation } = addressApis