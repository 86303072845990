import { Box, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, TableLoader } from "../../components/Common/Table";
import { MdMoreVert } from "react-icons/md";
import { dummy } from "../../helper/Constant";
import { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { useDeleteAdminMutation, useGetAdminListQuery, useStatusChangeAdminMutation } from "../../services/Admin";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../components/Common/Message/MessageContext";

export default function AdminMgt() {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const [page, setPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' });
  const { data, error, isLoading } = useGetAdminListQuery({ page: page + 1 });
  const adminList = data?.data;
  const [deleteAdmin] = useDeleteAdminMutation()
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [changeStatus] = useStatusChangeAdminMutation();

  const handleStatusChange = async (adminId, adminStatus) => {
    const isBlocking = adminStatus === 1; // Determine if the current action is blocking the admin
    setSelectedAdmin({ id: adminId, status: adminStatus });
    setIsModalOpen({
      open: true,
      currentComponent: "changeStatus",
      head: isBlocking ? "Block Admin" : "Unblock Admin",
      isBlocking,
    });
  };

  const handleStatusChangeSubmit = async (reason) => {
    if (isModalOpen.isBlocking && !reason) {
      showMessage('error', 'Reason is required');
      return;
    }

    try {
      const response = await changeStatus({
        id: selectedAdmin.id,
        formData: isModalOpen.isBlocking ? { reason } : {},
      });

      const { status, message } = response.data || {};

      if (status) {
        showMessage('success', message);
        // Refresh the admin list or update the local state
      } else {
        showMessage('error', message);
      }
    } catch (error) {
      showMessage('error', 'An error occurred while changing status');
    }

    setIsModalOpen({ open: false, currentComponent: "", head: '' });
    setSelectedAdmin(null);
  };

  return (
    <>
      <Stack>
        <Stack my={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Heading head={'Admin Management'} />
          <Button buttonName='Add Admin' onClick={() => navigate('/add-admin')} />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mobile No.</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Block/Unblock</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (<TableLoader colspan='8' />) :
              adminList && adminList.length > 0 ?
                (adminList?.map((ele, i) => {
                  const { name, mobile, cityName, stateName, status, id, profileImage } = ele;
                  return (
                    <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>
                        <img src={profileImage} alt="banner_image" width={'70px'} height='70px' style={{ objectFit: 'cover' }} />
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{mobile}</TableCell>
                      <TableCell>{cityName ? cityName : '-'}</TableCell>
                      <TableCell>{stateName ? stateName : '-'}</TableCell>
                      <TableCell>
                        <Switch
                          checked={status === 1}
                          onClick={() => handleStatusChange(id, status)}
                        />
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <DropdownMenu.Root>
                            <DropdownMenu.Trigger className='dropDown'>
                              <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content className='dropDownContent' align='end'>
                              <DropdownMenu.Item className='dropDownMenu' onClick={() => navigate('/add-admin', { state: { id: ele?.id, data: ele } })}>
                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                              </DropdownMenu.Item>
                              {/* <DropdownMenu.Item className='dropDownMenu' onClick={() => setIsModalOpen({ open: true, currentComponent: "actionModal", head: "Delete Banner", para: "Are you sure you want to delete this banner?", id: ele?.id, delete: deleteAdmin })}>
                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }} >Delete</Box></Stack>
                              </DropdownMenu.Item> */}
                            </DropdownMenu.Content>
                          </DropdownMenu.Root>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                }))
                : <NoRecordFound colspan='8' />
            }
          </TableBody>
        </Table>
      </TableContainer>
      {adminList && adminList.length > 0 ? <Pagination totalData={data?.total || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
      <Modal
        modalOpen={isModalOpen}
        onSubmit={handleStatusChangeSubmit}
        handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", data: '', delete: '' })}
      />
    </>
  );
}
