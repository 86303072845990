import { Box, Chip, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BackButton, Heading, Input } from '../../components/Common'
import { useMessage } from '../../components/Common/Message/MessageContext';
import { useLocation } from 'react-router-dom';
import { useGetjobDetailQuery } from '../../services/Job';
import moment from 'moment';
import { dummy } from '../../helper/Constant';
import { green, red, yellow, blue, grey, orange, purple, brown, pink, indigo } from '@mui/material/colors';


const JobDetails = () => {
    const { showMessage } = useMessage();
    const { state } = useLocation();
    const [jobId, setJobId] = useState();

    const { data, error, isLoading } = useGetjobDetailQuery({ id: state?.id }, { skip: !state?.id });

    const jobDetails = data?.data;

    const userDetails = jobDetails?.userId;
    const employeeDetails = jobDetails?.employeeId;
    const serviceDetails = jobDetails?.jobServiceData;
    const billingDetails = jobDetails?.jobCompleteData;
    const jobStatus = jobDetails?.OrderStatusData;
    const JobCompletedImage = jobDetails?.JobCompletedImage;


    useEffect(() => {
        if (state) {
            setJobId(state?.id)
        }
    }, [state]);

    const calculateTotalPrice = (servicePrice, offer, quantity) => {
        return (servicePrice * quantity) - offer;
    };
    const discTotalPrice = (servicePrice, offer, quantity) => {
        return (servicePrice * (offer * quantity)) / 100;
    };

    const getStatusChip = (status) => {
        switch (status) {
            case 0:
                return <Chip size="small" label="Upcoming or Placed" style={{ backgroundColor: grey[500], color: 'white' }} />;
            case 1:
                return <Chip size="small" label="Assigned to Technician" style={{ backgroundColor: blue[500], color: 'white' }} />;
            case 2:
                return <Chip size="small" label="On the Way" style={{ backgroundColor: yellow[700], color: 'white' }} />;
            case 3:
                return <Chip size="small" label="Work in Progress" style={{ backgroundColor: orange[500], color: 'white' }} />;
            case 4:
                return <Chip size="small" label="Hold" style={{ backgroundColor: brown[500], color: 'white' }} />;
            case 5:
                return <Chip size="small" label="Completed" style={{ backgroundColor: green[500], color: 'white' }} />;
            case 6:
                return <Chip size="small" label="Client Cancelled" style={{ backgroundColor: red[500], color: 'white' }} />;
            case 7:
                return <Chip size="small" label="Verified" style={{ backgroundColor: purple[500], color: 'white' }} />;
            case 8:
                return <Chip size="small" label="Employee Cancelled" style={{ backgroundColor: pink[500], color: 'white' }} />;
            case 9:
                return <Chip size="small" label="On Going" style={{ backgroundColor: indigo[500], color: 'white' }} />;
            default:
                return '-';
        }
    };

    const getPaymentStatus = (status) => {
        switch (status) {
            case 0:
                return "Cash";
            case 1:
                return "Online";
            default:
                return '-';
        }
    };
    return (
        <>
            <Stack flexDirection={'row'} gap={1} mb={1}>
                <BackButton />
                <Heading head='Job Details' />
            </Stack>
            <Box className='card_container'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Heading smallHead={'User Details'} />
                        <Stack flexDirection={'column'} gap={{ xs: '2px' }} >
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Name :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{userDetails?.name ? userDetails?.name : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Mobile :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{userDetails?.mobile ? userDetails?.mobile : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Email :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{userDetails?.email ? userDetails?.email : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Active Address :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.addressType ? jobDetails?.addressType : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Heading smallHead={'Address Details'} />
                        <Stack flexDirection={'column'} gap={{ xs: '2px' }} >
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>House No. :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.house ? jobDetails?.house : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Building Name :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.buildingName ? jobDetails?.buildingName : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Landmark :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.landmark ? jobDetails?.landmark : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'} sx={{ whiteSpace: 'nowrap' }}>Address :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.address ? jobDetails?.address : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>City :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.city ? jobDetails?.city : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Heading smallHead={'Billing Summary'} />
                        <Stack flexDirection={'column'} gap={{ xs: '2px' }} >
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Paid By :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{userDetails?.name ? userDetails?.name : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Labour Amount :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{billingDetails?.cashAmount ? `₹ ${billingDetails?.cashAmount}.00` : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Material Amount :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{billingDetails?.matirealAmount ? `₹ ${billingDetails?.matirealAmount}.00` : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Final Total Amount :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{billingDetails?.matirealAmount && billingDetails?.cashAmount ? `₹ ${parseFloat(billingDetails?.matirealAmount) + parseFloat(billingDetails?.cashAmount)}.00` : '-'}</Typography>
                            </Stack>
                            <Stack gap={1} flexDirection={'row'} sx={{ background: 'var(--bg)', padding: '10px' }}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Payment Status :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{billingDetails?.paymentStatus ? billingDetails?.paymentStatus : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Heading smallHead={'Schedule Details'} />
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Stack flexDirection={'column'} flexWrap={'wrap'} gap={{ xs: 1 }} pt={1}>
                                    <Stack gap={1} flexDirection={'row'}>
                                        <Typography fontSize={'14px'} color={'var(--text)'}>Date & Time :</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'500'}>
                                            {jobDetails?.orderDate ? moment(jobDetails?.orderDate).format('DD MMM YYYY') : '-'} & {jobDetails?.orderSlot?.slotTime}
                                        </Typography>
                                    </Stack>
                                    <Stack gap={1} flexDirection={'row'}>
                                        <Typography fontSize={'14px'} color={'var(--text)'}>Start Date :</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'500'}>
                                            {jobDetails?.startDate ? moment(jobDetails?.startDate).format('DD-MM-YYYY') : '-'}
                                        </Typography>
                                    </Stack>
                                    <Stack gap={1} flexDirection={'row'}>
                                        <Typography fontSize={'14px'} color={'var(--text)'}>End Date :</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'500'}>
                                            {jobDetails?.endDate ? moment(jobDetails?.endDate).format('DD-MM-YYYY') : '-'}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Stack flexDirection={'column'} flexWrap={'wrap'} gap={{ xs: 1 }} pt={1}>
                                    <Stack gap={1} flexDirection={'row'}>
                                        <Typography fontSize={'14px'} color={'var(--text)'}>Job Status :</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'500'}>{getStatusChip(jobDetails?.orderStatus)}</Typography>
                                    </Stack>
                                    <Stack gap={1} flexDirection={'row'}>
                                        <Typography fontSize={'14px'} color={'var(--text)'}>Job Start Time :</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.endDate ? moment(jobDetails?.startDate).format('hh:mm A') : '-'}</Typography>
                                    </Stack>
                                    <Stack gap={1} flexDirection={'row'}>
                                        <Typography fontSize={'14px'} color={'var(--text)'}>Job End Time :</Typography>
                                        <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.endDate ? moment(jobDetails?.endDate).format('hh:mm A') : '-'}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack gap={1} flexDirection={'row'} mb={jobDetails?.attachment ? 1 : 0}>
                            <Typography fontSize={'14px'} color={'var(--text)'}>Job Note :</Typography>
                            <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.note ? jobDetails?.note : '-'}</Typography>
                        </Stack>
                        {jobDetails?.attachment &&
                            <img src={jobDetails?.attachment} alt="banner_image" width={'100px'} height='100px' style={{ objectFit: 'cover' }} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack gap={1} flexDirection={'row'}>
                            <Typography fontSize={'14px'} color={'var(--text)'}>Cancel Job Remark :</Typography>
                            <Typography fontSize={'14px'} fontWeight={'500'}>{jobDetails?.reason ? jobDetails?.reason : '-'}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Heading smallHead={'Technician Details'} />
                        <Stack flexDirection={'row'} flexWrap={'wrap'} gap={{ xs: 5 }} pt={1}>
                            <Stack flexDirection={'row'} gap={1}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Name :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{employeeDetails?.name ? employeeDetails?.name : '-'}</Typography>
                            </Stack>
                            <Stack flexDirection={'row'} gap={1}>
                                <Typography fontSize={'14px'} color={'var(--text)'}>Mobile :</Typography>
                                <Typography fontSize={'14px'} fontWeight={'500'}>{employeeDetails?.mobile ? employeeDetails?.mobile : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Heading smallHead={'Service Details'} />
                        <Stack flexDirection={'row'} flexWrap={'wrap'} gap={{ xs: 2, sm: 3, md: 4 }} pt={1} sx={{ overflow: 'auto' }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Service</TableCell>
                                        <TableCell>QTY</TableCell>
                                        <TableCell>Single Charge</TableCell>
                                        <TableCell>Discount</TableCell>
                                        <TableCell className='job_table'>Total Charge</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {serviceDetails?.map((ele, i) => {
                                        const { serviceName, quantity, price, discount } = ele;
                                        const discPrice = discTotalPrice(price, discount, quantity);
                                        const totalPrice = calculateTotalPrice(price, discPrice, quantity);
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>{serviceName}</TableCell>
                                                <TableCell>{quantity}</TableCell>
                                                <TableCell>₹ {price}</TableCell>
                                                <TableCell>₹ {discPrice.toFixed(2)} &nbsp; ( {discount}% )</TableCell>
                                                <TableCell className='job_table'>₹ {totalPrice.toFixed(2)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Stack>
                        <Stack maxWidth={'300px'} marginLeft={'auto'} gap={'5px'} mt={2}>
                            <Stack flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography fontSize='14px'>Total Amount</Typography>
                                <Typography fontSize='14px'>₹ {jobDetails?.totalAmount - jobDetails?.totalDiscount - jobDetails?.extraCharge}.00</Typography>
                            </Stack>
                            <Divider />
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Typography fontSize={'14px'}>Extra Charge</Typography>
                                <Typography fontSize={'14px'}>₹ {jobDetails?.extraCharge}.00</Typography>
                            </Stack>
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Typography fontSize={'14px'}>Discount</Typography>
                                <Typography fontSize={'14px'}>₹ {jobDetails?.totalDiscount}.00</Typography>
                            </Stack>
                            <Divider />
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Typography fontWeight={'600'}>Total Amount</Typography>
                                <Typography fontWeight={'600'}>₹ {jobDetails?.totalAmount}.00</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Heading smallHead={'Service Status'} />
                        <Stack flexDirection={'row'} flexWrap={'wrap'} gap={{ xs: 2, sm: 3, md: 4 }} pt={1} sx={{ overflow: 'auto' }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Job Status</TableCell>
                                        <TableCell>Lat</TableCell>
                                        <TableCell>Long</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Remark</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobStatus?.map((ele, i) => {
                                        const { lat, long, image, orderStatus, createdAt, jobCompleteData, remarks } = ele;
                                        console.log(jobCompleteData, 'jobCompleteData');

                                        return (
                                            <TableRow key={i}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>
                                                    {image ?
                                                        <img src={image} alt="banner_image" width={'100px'} height='100px' style={{ objectFit: 'cover' }} />
                                                        : (orderStatus == 5 ?
                                                            <Stack flexDirection={'row'} gap={1}>
                                                                {JobCompletedImage?.map((item, i) => {
                                                                    return (
                                                                        <img key={i} src={item?.image ? item?.image : dummy} alt="banner_image" width={'100px'} height='100px' style={{ objectFit: 'cover' }} />
                                                                    )
                                                                })}
                                                            </Stack>
                                                            : '-'
                                                        )}
                                                </TableCell>
                                                <TableCell>{getStatusChip(orderStatus)}</TableCell>
                                                <TableCell>{lat ? lat : '-'}</TableCell>
                                                <TableCell>{long ? long : '-'}</TableCell>
                                                <TableCell>{moment(createdAt).format('DD-MM-YYYY')}</TableCell>
                                                <TableCell>{moment(createdAt).format('HH:mm')}</TableCell>
                                                <TableCell>{remarks ? remarks : '-'}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default JobDetails