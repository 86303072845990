import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const clientApis = createApi({
  reducerPath: 'clientApis',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Client'],
  endpoints: (builder) => ({
    getClientList: builder.query({
      query: ({ page, search }) => `clientList?page=${page}&search=${search}`,
      providesTags: ['Client'],
    }),
    getClientDetail: builder.query({
      query: (id) => `clientDetail/${id}`,
      providesTags: ['Client'],
    }),
    addNewClient: builder.mutation({
      query: (body) => {
        return { url: `addClient`, body, method: "POST" }
      },
      invalidatesTags: ['Client'],
    }),
    addAddress: builder.mutation({
      query: (body) => {
        return { url: `addAddress`, body, method: "POST" }
      },
      invalidatesTags: ['Client'],
    }),
    updateClient: builder.mutation({
      query: (body) => {
        const { id, formData } = body;
        return { url: `updateClient/${id}`, body: formData, method: "PUT" }
      },
      invalidatesTags: ['Client'],
    }),
    deleteClient: builder.mutation({
      query: (id) => {
        return { url: `deleteClient/${id}`, method: "DELETE" }
      },
      invalidatesTags: ['Client'],
    }),
    getDropdownClient: builder.query({
      query: () => `dropdownClient`,
      providesTags: ['Client'],
    }),
  }),
})

export const { useGetClientListQuery, useGetClientDetailQuery, useAddAddressMutation, useAddNewClientMutation, useDeleteClientMutation, useUpdateClientMutation, useGetDropdownClientQuery } = clientApis