import React, { useEffect, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import { Button, ErrorMessage, Input, Select } from '../../components/Common'
import { useGetCityListQuery, useGetStateListQuery } from '../../services/Location'
import { useMessage } from '../../components/Common/Message/MessageContext'
import { addressTypeList, countryList } from '../../helper'
import { useAddUserAddressMutation, useUpdateAddressMutation } from '../../services/Address'

const AddAddress = ({ handleClose, modalOpen, onAddressAdded }) => {
    const { showMessage } = useMessage();
    const [country, setCountry] = useState(1);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [addressType, setAddressType] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        house: '',
        building: '',
        address: '',
        landmark: '',
        latitude: '',
        longitude: '',
    });

    const { data: stateLst, error: stateError, isLoading: stateLoading } = useGetStateListQuery();
    const { data: cityLst, error: cityError, isLoading: cityLoading } = useGetCityListQuery(state, { skip: !state });

    const cityList = cityLst?.data;
    const stateList = stateLst?.data;

    const handleStateChange = (e) => {
        setState(e.target.value);
    }
    const handleCountryChange = (e) => setCountry(e.target.value)
    const handleCityChange = (e) => setCity(e.target.value)
    const handleaddressTypeChange = (e) => setAddressType(e.target.value)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validateForm = () => {
        let errors = {};
        let hasError = false;

        Object.keys(formValues).forEach(key => {
            if (!formValues[key]) {
                errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
                hasError = true;
            }
        });

        if (!addressType) {
            errors.addressType = 'Address Type is required';
            hasError = true;
        }
        if (!modalOpen?.isEdit && !country) {
            errors.country = 'Country is required';
            hasError = true;
        }
        if (!modalOpen?.isEdit && !state) {
            errors.state = 'State is required';
            hasError = true;
        }
        if (!modalOpen?.isEdit && !city) {
            errors.city = 'City is required';
            hasError = true;
        }

        setErrorMessages(errors);
        return !hasError;
    };

    const [addAddress] = useAddUserAddressMutation();
    const [updateAddress] = useUpdateAddressMutation();


    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            try {
                let response;

                if (modalOpen.isEdit) {
                    const address = {
                        ...formValues,
                        type: addressType,
                        userId: modalOpen?.userId
                    }
                    response = await updateAddress({ body: address, id: modalOpen?.addressData?.addressId, jobId: modalOpen?.jobId });
                } else {
                    const address = {
                        ...formValues,
                        cityId: city.toString(),
                        state: state.toString(),
                        type: addressType,
                        userId: modalOpen?.userId
                    }
                    response = await addAddress(address);
                }

                const { status, message } = response?.data;

                if (status) {
                    handleClose();
                    showMessage('success', message);
                    if (onAddressAdded) {
                        onAddressAdded();
                    }
                } else {
                    showMessage('error', message || 'An error occurred');
                }
            } catch (error) {
                showMessage('error', 'Failed to save the address');
            } finally {
                setLoading(false); // Set loading to false after submission completes
            }
        } else {
            showMessage('error', 'Please fill in all required fields');
        }
    }

    useEffect(() => {
        if (modalOpen?.addressData) {
            const { address, buildingName, house, lat, long, landmark } = modalOpen.addressData
            setFormValues({ address, building: buildingName, house, latitude: lat, longitude: long, landmark });
            setCity(modalOpen.addressData.city || '');
            setAddressType(modalOpen.addressData.addressType || '');
            setCountry(modalOpen.addressData.country || 1);
            setState(modalOpen?.addressData?.state || '');
        } else {
            setFormValues({
                house: '',
                building: '',
                address: '',
                landmark: '',
                latitude: '',
                longitude: '',
            });
            setCity('');
            setState('');
            setAddressType('');
            setCountry(1);
        }
    }, [modalOpen?.addressData])

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : "Add"} Address
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='House No.' name='house' value={formValues.house} onChange={handleInputChange} />
                        {errorMessages.house && <ErrorMessage message={errorMessages.house} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Building Name' name='building' value={formValues.building} onChange={handleInputChange} />
                        {errorMessages.building && <ErrorMessage message={errorMessages.building} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Address' name='address' value={formValues.address} onChange={handleInputChange} />
                        {errorMessages.address && <ErrorMessage message={errorMessages.address} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Landmark' name='landmark' value={formValues.landmark} onChange={handleInputChange} />
                        {errorMessages.landmark && <ErrorMessage message={errorMessages.landmark} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select selectList={addressTypeList} labelinput='Address Type' value={addressType} onChange={handleaddressTypeChange} />
                        {errorMessages.addressType && <ErrorMessage message={errorMessages.addressType} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Select disabled={modalOpen?.isEdit} selectList={countryList} labelinput='Country' value={country} onChange={handleCountryChange} />
                        {errorMessages.country && <ErrorMessage message={errorMessages.country} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {modalOpen?.isEdit ?
                            <Input labelinput='State' value={state} disabled={modalOpen?.isEdit} />
                            :
                            <>
                                <Select selectList={stateLoading ? [] : stateList} labelinput='State' value={state} onChange={handleStateChange} />
                                {errorMessages.state && <ErrorMessage message={errorMessages.state} />}
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {modalOpen?.isEdit ?
                            <Input labelinput='City' value={city} disabled={modalOpen?.isEdit} />
                            :
                            <>
                                <Select selectList={cityLoading ? [] : cityList} labelinput='City' value={city} onChange={handleCityChange} />
                                {errorMessages.city && <ErrorMessage message={errorMessages.city} />}
                            </>
                        }

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Latitude' type='number' name='latitude' value={formValues.latitude} onChange={handleInputChange} />
                        {errorMessages.latitude && <ErrorMessage message={errorMessages.latitude} />}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input labelinput='Longitude' type='number' name='longitude' value={formValues.longitude} onChange={handleInputChange} />
                        {errorMessages.longitude && <ErrorMessage message={errorMessages.longitude} />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} disabled={loading} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} disabled={loading} />
            </DialogActions>
        </>
    )
}

export default AddAddress