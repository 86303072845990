import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const slotApis = createApi({
    reducerPath: 'slotApis',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['slot'],
    endpoints: (builder) => ({
        getslotList: builder.query({
            query: ({ date }) => `slotList?date=${date}`,
            providesTags: ['slot'],
        }),
        slotDisable: builder.mutation({
            query: (body) => {
                const { id, formData } = body;
                return { url: `slotDisable/${id}`, body: formData, method: "POST" }
            },
            invalidatesTags: ['slot'],
        }),
        slotListforjob: builder.mutation({
            query: (rest) => {
                return { url: `slotListforjob`, body: rest, method: "POST" }
            },
            invalidatesTags: ['slot'],
        }),
    }),
})

export const { useGetslotListQuery, useSlotDisableMutation, useSlotListforjobMutation } = slotApis