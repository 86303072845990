import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const reportApis = createApi({
    reducerPath: 'reportApis',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        customerReport: builder.mutation({
            query: () => {
                return { url: `customerExcelData`, method: "POST", body: {}, }
            },
        }),
        technicianReport: builder.mutation({
            query: () => {
                return { url: `technicianExcelData`, method: "POST", body: {}, }
            },
        }),
        serviceReport: builder.mutation({
            query: ({ sId }) => {
                return { url: `serviceExcelData?serviceDepartmentId=${sId}`, method: "POST", body: {}, }
            },
        }),
        allJobReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `orderExcelData?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        singleJobReport: builder.mutation({
            query: (body) => {
                return { url: `exportSingleJobData`, method: "POST", body: body, }
            },
        }),
        holdJobReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `fetchHeldJobs?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        cancelJobReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `exportCancelJobs?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        customerJobReport: builder.mutation({
            query: ({ cId, month }) => {
                return { url: `exportJobDataToExcel?clientId=${cId}&month=${month}`, method: "POST", body: {}, }
            },
        }),
        allJobPaymentReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `allJobPayment?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        pendingPaymentReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `completeJobPayment?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        recievedPaymentReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `verifyJobPayment?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        rattingReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `exportRatting?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        feedbackReport: builder.mutation({
            query: ({ fDate, tDate, cId, tId }) => {
                return { url: `exportJobDetailsToExcel?fromDate=${fDate}&toDate=${tDate}&cityId=${cId}&technicianId=${tId}`, method: "POST", body: {}, }
            },
        }),
        jobNumberDropdown: builder.mutation({
            query: ({ userId }) => {
                return { url: `orderNumberDropdown?clientId=${userId}`, method: "POST", }
            },
        }),
        employeeDropdown: builder.mutation({
            query: () => {
                return { url: `employeeDropdownReport` }
            },
        }),
        getEmployeeDropdown: builder.query({
            query: () => `employeeDropdownReport`,
        }),
    }),
})

export const { useCustomerReportMutation, useTechnicianReportMutation, useServiceReportMutation, useAllJobReportMutation, useSingleJobReportMutation,
    useHoldJobReportMutation, useCancelJobReportMutation, useCustomerJobReportMutation, useAllJobPaymentReportMutation, usePendingPaymentReportMutation,
    useRecievedPaymentReportMutation, useRattingReportMutation, useJobNumberDropdownMutation, useGetEmployeeDropdownQuery, useFeedbackReportMutation } = reportApis