import { Paper, Rating, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { Button, Heading, Modal } from "../../components/Common";
import { NoRecordFound, Pagination, TableLoader } from "../../components/Common/Table";
import { useState } from "react";
import { useGetRattingListQuery, useRattingApproveMutation } from "../../services/Rating";
import { useMessage } from "../../components/Common/Message/MessageContext";

export default function Review() {
    const { showMessage } = useMessage();
    const [page, setPage] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", url: '', delete: '' });
    const { data, error, isLoading } = useGetRattingListQuery({ page: page + 1 });
    const [rattingApproval] = useRattingApproveMutation();
    const reviewList = data?.data;

    const handleClick = async ({ approveId, approveStatus }) => {
        let response = await rattingApproval({ approveId });
        const { status, message } = response?.data || {};
        if (status) {
            if (approveStatus === 0) {
                showMessage('success', message);
            } else {
                showMessage('success', message);
            }
        } else {
            showMessage('error', message);
        }
    };

    return (
        <>
            <Stack>
                <Stack my={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Heading head={'Ratting'} />
                    <Button buttonName='Add Ratting' onClick={() => setIsModalOpen({ open: true, currentComponent: "addReview", isEdit: false, id: '', url: '' })} />
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Ratting</TableCell>
                            <TableCell>Review</TableCell>
                            <TableCell>Approve</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='5' />) :
                            reviewList && reviewList.length > 0 ?
                                (reviewList?.map((ele, i) => {
                                    const { review, ratting, status, id, userName } = ele;
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{userName?.name}</TableCell>
                                            <TableCell>
                                                {ratting ?
                                                    <Rating name="half-rating" defaultValue={ratting} readOnly precision={0.5} />
                                                    : '-'}</TableCell>
                                            <TableCell>
                                                <Tooltip title={review}>
                                                    {review ? (review.length > 30 ? `${review.slice(0, 30)}...` : review) : '-'}
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Switch defaultChecked
                                                    checked={status === 1}
                                                    onClick={() => handleClick({ approveId: id, approveStatus: status })}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan='5' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {reviewList && reviewList.length > 0 ? <Pagination totalData={data?.totalData || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
            <Modal
                modalOpen={isModalOpen}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, id: "", url: '', delete: '' })}
            />
        </>
    );
}
