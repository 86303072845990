import { Stack, Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, ErrorMessage, Heading, Input } from '../../components/Common';
import { useGetsettingsListQuery, useUpdateSettingMutation } from '../../services/settings';
import { useMessage } from '../../components/Common/Message/MessageContext';

const Settings = () => {
  const { showMessage } = useMessage();
  const { data, error, isLoading } = useGetsettingsListQuery();
  const settingsData = data?.data[0];
  const [radius, setRadius] = useState('');
  const [night, setNight] = useState('');
  const [cash, setCash] = useState('');
  const [edit, setEdit] = useState(true);
  const [radiusErrorMessage, setRadiusErrorMessage] = useState('');
  const [nightErrorMessage, setNightErrorMessage] = useState('');
  const [cashErrorMessage, setCashErrorMessage] = useState('');
  const [updateSettings] = useUpdateSettingMutation();

  const handleSubmit = async () => {
    // Reset error messages
    setRadiusErrorMessage('');
    setNightErrorMessage('');
    setCashErrorMessage('');

    // Validation for mandatory fields
    let hasError = false;
    if (!radius) {
      setRadiusErrorMessage('Order Radius is a mandatory field.');
      hasError = true;
    }
    if (!night) {
      setNightErrorMessage('Night Charge is a mandatory field.');
      hasError = true;
    }
    if (!cash) {
      setCashErrorMessage('Min Cash is a mandatory field.');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const formData = {
      orderRadius: +radius,
      nightCharge: +night,
      minCash: +cash
    }

    try {
      let response = await updateSettings(formData);

      const { status, message } = response?.data || {};

      if (status) {
        showMessage('success', message);
      } else {
        showMessage('error', message);
      }
    } catch (error) {
      showMessage('error', 'An error occurred while processing your request.');
    } finally {
      console.log('finally');
      setEdit(true);
    }
  };


  useEffect(() => {
    setRadius(settingsData?.orderRadius);
    setNight(settingsData?.nightCharge);
    setCash(settingsData?.minCash);
  }, [settingsData])

  const handleEditSetting = () => setEdit((show) => !show);

  return (
    <>
      <Stack mb={1}>
        <Heading head={'Settings'} />
      </Stack>
      <Box sx={{ background: 'var(--white)', border: '1px solid var(--border)', borderRadius: '6px', maxWidth: '500px' }} p={{ xs: 3, sm: 4 }}>
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid item xs={12}>
            <Input disabled={edit} type='number' labelinput='Order Radius' value={radius} onChange={(e) => setRadius(e.target.value)} />
            <ErrorMessage message={radiusErrorMessage} />
          </Grid>
          <Grid item xs={12}>
            <Input disabled={edit} type='number' labelinput='Night Charge' value={night} onChange={(e) => setNight(e.target.value)} />
            <ErrorMessage message={nightErrorMessage} />
          </Grid>
          <Grid item xs={12}>
            <Input disabled={edit} type='number' labelinput='Min Cash' value={cash} onChange={(e) => setCash(e.target.value)} />
            <ErrorMessage message={cashErrorMessage} />
          </Grid>
          <Grid item xs={12}>
            {edit ?
              <Button buttonName='Edit' onClick={handleEditSetting} />
              : <Button buttonName='Update' onClick={handleSubmit} />
            }
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Settings;
