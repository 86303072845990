import { Box, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button, Heading, MenuButton } from "../../components/Common";
import { NoRecordFound, Pagination, Search, TableLoader } from "../../components/Common/Table";
import { MdMoreVert, MdOutlineRemoveRedEye } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import useDebounce from "../../helper/utils";
import { useGetJobListQuery } from "../../services/Job";
import moment from "moment";
import { green, red, yellow, blue, grey, orange, purple, brown, pink, indigo } from '@mui/material/colors';

export default function JobMgt() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchData, setSearchData] = useState('')
    const search = useDebounce(searchData, 500)
    const { data, error, isLoading } = useGetJobListQuery({ page: page + 1, search, orderStatus: `${state === 'cancellJob' ? '' : 0}`, type: state });
    const jobList = data?.data;

    const getStatusChip = (status) => {
        switch (status) {
            case 0:
                return <Chip size="small" label="Upcoming or Placed" style={{ backgroundColor: grey[500], color: 'white' }} />;
            case 1:
                return <Chip size="small" label="Assigned to Technician" style={{ backgroundColor: blue[500], color: 'white' }} />;
            case 2:
                return <Chip size="small" label="On the Way" style={{ backgroundColor: yellow[700], color: 'white' }} />;
            case 3:
                return <Chip size="small" label="Work in Progress" style={{ backgroundColor: orange[500], color: 'white' }} />;
            case 4:
                return <Chip size="small" label="Hold" style={{ backgroundColor: brown[500], color: 'white' }} />;
            case 5:
                return <Chip size="small" label="Completed" style={{ backgroundColor: green[500], color: 'white' }} />;
            case 6:
                return <Chip size="small" label="Client Cancelled" style={{ backgroundColor: red[500], color: 'white' }} />;
            case 7:
                return <Chip size="small" label="Verified" style={{ backgroundColor: purple[500], color: 'white' }} />;
            case 8:
                return <Chip size="small" label="Employee Cancelled" style={{ backgroundColor: pink[500], color: 'white' }} />;
            case 9:
                return <Chip size="small" label="On Going" style={{ backgroundColor: indigo[500], color: 'white' }} />;
            default:
                return '-';
        }
    };

    return (
        <>
            <Stack>
                <Heading head={'Jobs Management'} />
                <Stack my={1} flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={1} justifyContent={'space-between'} alignItems={{ xs: 'flex-end', sm: 'center' }}>
                    <Search setSearch={setSearchData} search={searchData} setPage={setPage} />
                    <Button buttonName='Add Jobs' onClick={() => navigate('/add-job')} />
                </Stack>
            </Stack>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Job ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Category Name</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Technician Name</TableCell>
                            <TableCell>Technician Mobile</TableCell>
                            <TableCell>Complete OTP</TableCell>
                            <TableCell>Job Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (<TableLoader colspan='11' />) :
                            jobList && jobList.length > 0 ?
                                (jobList?.map((ele, i) => {
                                    const { orderNumber, orderDate, employeeName, employeeMobile, serviceDepartmentName, clientName, clientMobile, clientOtp, orderStatus } = ele;
                                    const formattedDate = moment(orderDate).format('DD-MM-YYYY');
                                    return (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }} onClick={() => navigate('/view-job', { state: { id: ele?.id, data: ele } })}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{orderNumber}</TableCell>
                                            <TableCell>{formattedDate}</TableCell>
                                            <TableCell>{serviceDepartmentName}</TableCell>
                                            <TableCell>{clientName}</TableCell>
                                            <TableCell>{clientMobile ? clientMobile : '-'}</TableCell>
                                            <TableCell>{employeeName ? employeeName : '-'}</TableCell>
                                            <TableCell>{employeeMobile ? employeeMobile : '-'}</TableCell>
                                            <TableCell>{clientOtp ? clientOtp : '-'}</TableCell>
                                            <TableCell>{getStatusChip(orderStatus)}</TableCell>
                                            <TableCell onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <DropdownMenu.Root>
                                                        <DropdownMenu.Trigger className='dropDown'>
                                                            <MenuButton icon={<MdMoreVert style={{ color: '#000', fontSize: '18px' }} />} width='30px' height='33px' />
                                                        </DropdownMenu.Trigger>
                                                        <DropdownMenu.Content className='dropDownContent' align='end'>
                                                            <DropdownMenu.Item className='dropDownMenu' onClick={() => navigate('/edit-job', { state: { id: ele?.id, data: ele } })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>Edit</Box></Stack>
                                                            </DropdownMenu.Item>
                                                            {/* <DropdownMenu.Item className='dropDownMenu' onClick={() => navigate('/view-job', { state: { id: ele?.id, data: ele } })}>
                                                                <Stack flexDirection={'row'} gap={1} alignItems={'center'}><MdOutlineRemoveRedEye style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>View</Box></Stack>
                                                            </DropdownMenu.Item> */}
                                                        </DropdownMenu.Content>
                                                    </DropdownMenu.Root>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }))
                                : <NoRecordFound colspan='11' />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {jobList && jobList.length > 0 ? <Pagination totalData={data?.totalData || 0} page={page} setPage={setPage} rowsPerPage={10} /> : ''}
        </>
    );
}
