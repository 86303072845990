import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './utils';

export const thoughtApis = createApi({
    reducerPath: 'thoughtApis',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Thought'],
    endpoints: (builder) => ({
        getThoughtList: builder.query({
            query: ({ page }) => `thoughtList?page=${page}`,
            providesTags: ['Thought'],
        }),
        addNewThought: builder.mutation({
            query: (body) => {
                return { url: `createThought`, body, method: "POST" }
            },
            invalidatesTags: ['Thought'],
        }),
        updateThought: builder.mutation({
            query: (body) => {
                const { id, formData } = body;
                return { url: `updateThought/${id}`, body: formData, method: "PUT" }
            },
            invalidatesTags: ['Thought'],
        }),
        deleteThought: builder.mutation({
            query: (id) => {
                return { url: `deleteThought/${id}`, method: "DELETE" }
            },
            invalidatesTags: ['Thought'],
        }),
    }),
})

export const { useGetThoughtListQuery, useAddNewThoughtMutation, useDeleteThoughtMutation, useUpdateThoughtMutation } = thoughtApis