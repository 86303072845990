import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Rating, Typography } from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import { Button, Input, ErrorMessage, Select } from '../../components/Common';
import { useGetDropdownClientQuery } from '../../services/Client';
import { useMessage } from '../../components/Common/Message/MessageContext';
import { useAddRattingMutation } from '../../services/Rating';

const AddReview = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();

    const [message, setMessage] = useState('');
    const [ratting, setRatting] = useState(2.5);
    const [selectClient, setSelectClient] = useState('');

    const [messageErrorMessage, setMessageErrorMessage] = useState('');
    const [userErrorMessage, setUserErrorMessage] = useState('');
    const [rattingErrorMessage, setRattingErrorMessage] = useState('');
    const { data: clientList, error: clientError, isLoading: clientLoading } = useGetDropdownClientQuery();
    const clientlist = clientList?.data;

    const [addRatting] = useAddRattingMutation();
    const handleClientChange = (e) => setSelectClient(e.target.value);

    const handleSubmit = async () => {
        // Reset error messages
        setMessageErrorMessage('');
        setUserErrorMessage('');
        setRattingErrorMessage('');

        // Validation for mandatory fields
        let hasError = false;
        if (!message) {
            setMessageErrorMessage('Message is a mandatory field.');
            hasError = true;
        }
        if (!selectClient) {
            setUserErrorMessage('Client is a mandatory field.');
            hasError = true;
        }
        if (!ratting) {
            setRattingErrorMessage('Ratting is a mandatory field.');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const formData = {
            ratting: ratting,
            userId: selectClient,
            review: message,
        }

        try {
            let response = await addRatting(formData);

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
            handleClose();
        }
    };

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Add'} Ratting
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>Ratting</Typography>
                        <Rating name="half-rating" defaultValue={2.5} precision={0.5} onChange={(event, newValue) => {
                            setRatting(newValue);
                        }} />
                        <ErrorMessage message={rattingErrorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Select selectList={clientLoading ? [] : clientlist} value={selectClient} labelinput='Select Client'
                            onChange={handleClientChange} />
                        <ErrorMessage message={userErrorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Message' value={message} multiline rows={2} onChange={(e) => setMessage(e.target.value)} />
                        <ErrorMessage message={messageErrorMessage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    );
};

export default AddReview;
