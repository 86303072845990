import { Autocomplete, Box, Checkbox, Divider, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { BackButton, Button, Heading, Input, Modal, Select, UploadImage } from '../../components/Common'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetjobDetailQuery, useUpdateJobMutation } from '../../services/Job'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useGetdropdownServiceQuery } from '../../services/Services'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { useEmployeeDropdownMutation } from '../../services/Employee'
import { useGetCityListQuery } from '../../services/Location'
import { useMessage } from '../../components/Common/Message/MessageContext'
import AddressDropDown from '../Address/AddressDropDown'
import { useAddressDropdownMutation } from '../../services/Address'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditJob = () => {
    const navigate = useNavigate();

    const { showMessage } = useMessage();
    const { state } = useLocation();

    const [jobId, setJobId] = useState();
    const [address, setAddress] = useState(null);
    const [states, setStates] = useState(null);
    const [quantities, setQuantities] = useState({});
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [prevServices, setPrevServices] = useState([]);
    const [removedServices, setRemovedServices] = useState([]);
    const [removedEmployee, setRemovedEmployee] = useState([]);
    const [employeeList, setEmployeeList] = useState([])
    const [selectAddress, setSelectAddress] = useState(null);
    const [addressList, setAddressList] = useState(null);
    const [note, setNote] = useState('');
    const [noteImg, setNoteImg] = useState('');

    const [fData, setFormData] = useState({
        userId: '',
        orderDate: '',
        orderSlot: '',
        serviceDepartmentId: '',
    });
    const [isModalOpen, setIsModalOpen] = useState({
        open: false, setAddress: '', currentComponent: "", addressData: '',
        head: '', para: '', isEdit: false, userId: '', jobId: '',
    });

    const [updateJob, { isLoading: isUpdating }] = useUpdateJobMutation();
    const [employeeDropdown, { isLoading: employeeLoading }] = useEmployeeDropdownMutation({ skip: !state?.id });
    const { data, error, isLoading, refetch } = useGetjobDetailQuery({ id: state?.id }, { skip: !state?.id });
    const jobDetails = data?.data;
    const { data: cityLst, error: cityError, isLoading: cityLoading } = useGetCityListQuery(states, { skip: !states });
    const cityList = cityLst?.data;
    const { data: serviceList, error: serviceError, isLoading: serviceLoading } = useGetdropdownServiceQuery(state?.data?.serviceDepartmentId, { skip: !state?.data?.serviceDepartmentId });
    const service = serviceList?.data;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        setSelectedDate(dateValue);
        setFormData(prevData => ({
            ...prevData,
            orderDate: dateValue
        }));
    };
    const handleRefetchJobDetails = () => {
        refetch()
    }

    const handleEmployeeList = async (id) => {
        const response = await employeeDropdown({
            serviceDepartmentId: id,
            jobId: state?.id,
        });
        if (response?.data?.status) {
            setEmployeeList(response?.data?.data)
        }
    }

    const handleSelectChange = (event, value) => {
        let removed = selectedServices.filter(service => !value.some(v => v.id === service.id));
        setRemovedServices(prev => [...prev, ...removed.map(service => service.id)]);

        setSelectedServices(value);

        // Initialize quantities for newly selected services
        setQuantities(prevQuantities => {
            const updatedQuantities = { ...prevQuantities };
            value.forEach(service => {
                if (!updatedQuantities[service.id]) {
                    updatedQuantities[service.id] = service.quantity || 1;
                }
            });
            return updatedQuantities;
        });
    };

    const handleEmployeeListChange = (event, value) => {
        const removed = selectedEmployee?.filter(employee => !value.some(v => v.id === employee.id));
        setRemovedEmployee(prev => [...prev, ...removed.map(employee => employee.id)]);

        setSelectedEmployee(value)
    }

    const handleIncrement = (serviceId) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [serviceId]: (prevQuantities[serviceId] || 1) + 1
        }));
    };

    const handleDecrement = (serviceId) => {
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [serviceId]: Math.max(1, (prevQuantities[serviceId] || 1) - 1)
        }));
    };

    const calculateTotalPrice = (servicePrice, offer, quantity) => {
        return (servicePrice * quantity) - offer;
    };
    const discTotalPrice = (servicePrice, offer, quantity) => {
        return (servicePrice * (offer * quantity)) / 100;
    };

    const listOfTheServices = useMemo(() => {
        if (!service) return [];
        return service.filter((ele) => selectedServices.find((elem) => elem.serviceName === ele.serviceName)).map((elem) => ({ ...elem, quantity: selectedServices.find((el) => el.id === elem.id).quantity }))
    }, [service, selectedServices])

    const totalServicePrice = listOfTheServices?.reduce((acc, ele) => acc + (ele.servicePrice * (quantities[ele.id] || 1)), 0);
    const totalOfferPrice = listOfTheServices?.reduce((acc, ele) => acc + ((ele.servicePrice * ((ele.offer * quantities[ele.id]))) / 100), 0);
    const finalPrice = (totalServicePrice + jobDetails?.extraCharge) - totalOfferPrice;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();

            formData.append('userId', fData.userId);
            formData.append('orderDate', fData.orderDate);
            formData.append('orderSlot', fData.orderSlot);
            formData.append('serviceDepartmentId', fData.serviceDepartmentId);
            formData.append('addressId', selectAddress);
            formData.append('note', note);

            const services = listOfTheServices.map((service) => ({
                serviceId: service.id,
                subServiceId: service.subServiceId,
                quantity: quantities[service.id],
            }));
            formData.append('services', JSON.stringify(services));

            formData.append('deletedServiceIds', JSON.stringify(removedServices));
            formData.append('deletedEmployeeIds', JSON.stringify(removedEmployee));

            const employeeId = selectedEmployee.map(employee => employee.id);
            formData.append('employeeId', JSON.stringify(employeeId));

            if (noteImg) {
                formData.append('attachment', noteImg);
            }

            const response = await updateJob({ id: jobId, formData });

            const { status, message } = response?.data || {};
            if (status) {
                navigate('/jobs');
                showMessage('success', message);
            } else {
                showMessage('error', response?.error?.data?.message);
            }
        } catch (error) {
            console.error('Failed to update job:', error);
        }
    };
    const [addressDropdown] = useAddressDropdownMutation();

    useEffect(() => {
        const fetchData = async () => {
            if (jobDetails) {
                try {
                    const { data } = await addressDropdown({ userId: jobDetails?.userId?.id });
                    setAddressList(data?.data);

                    const { address, buildingName, house, lat, long, landmark, city, state, addressType, addressId } = jobDetails;
                    setAddress({ address, buildingName, house, lat, long, landmark, city, state, addressType, addressId });
                    setSelectedDate(jobDetails.orderDate.split('T')[0]);
                    setSelectAddress(jobDetails?.addressId)
                    setFormData(prevData => ({
                        ...prevData,
                        userId: jobDetails.userId.id,
                        orderDate: jobDetails.orderDate.split('T')[0],
                        orderSlot: jobDetails.orderSlot.id,
                        serviceDepartmentId: jobDetails.serviceDepartmentId.id,
                    }));
                    setNote(jobDetails?.note)
                    setNoteImg(jobDetails?.attachment)
                    setSelectedEmployee(jobDetails?.assignEmployeeList?.map((ele) => ({ id: ele?.id, name: ele?.name })));
                    setSelectedServices(jobDetails?.jobServiceData?.map((ele) => ({ serviceName: ele?.serviceName, id: ele?.serviceId, ids: ele?.id, quantity: ele?.quantity })));
                } catch (error) {
                    console.error('Error fetching address dropdown:', error);
                }
            }
        };

        fetchData();
    }, [jobDetails, cityList]);

    useEffect(() => {
        if (state) {
            setJobId(state?.id);
        }
        if (state?.id && state?.data?.serviceDepartmentId) {
            handleEmployeeList(state?.data?.serviceDepartmentId);
        }
    }, [state?.id, state?.data?.serviceDepartmentId]);

    useEffect(() => {
        if (listOfTheServices?.length > 0) {
            setQuantities(prevQuantities => {
                const updatedQuantities = { ...prevQuantities };

                listOfTheServices.forEach(service => {
                    // Check if the service is newly added or changed
                    if (!prevQuantities[service.id] || !prevServices.some(prevService => prevService.id === service.id)) {
                        // Set quantity to 1 for new services or keep the existing quantity for changed services
                        updatedQuantities[service.id] = service?.quantity || 1;
                    }
                });

                setPrevServices(listOfTheServices);
                return updatedQuantities;
            });
        }
    }, [listOfTheServices]);


    const getStatusChip = (status) => {
        switch (status) {
            case 0: return "Upcoming or Placed";
            case 1: return "Assigned to Technician";
            case 2: return "On the Way";
            case 3: return "Work in Progress";
            case 4: return "Hold";
            case 5: return "Completed";
            case 6: return "Client Cancelled";
            case 7: return "Verified";
            case 8: return "Employee Cancelled";
            case 9: return "On Going";
            default: return '-';
        }
    };

    return (
        <>
            <Stack flexDirection={'row'} gap={1} mb={1}>
                <BackButton />
                <Heading head='Edit Job' />
            </Stack>
            <Box className='card_container'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack flexDirection='row' justifyContent={'space-between'}>
                            <Stack flexDirection='row' gap={1} mt={1}>
                                <Typography fontSize='18px' color='var(--text)'>Service :</Typography>
                                <Typography fontSize='18px' fontWeight={500}>{jobDetails?.serviceDepartmentId?.serviceName}</Typography>
                            </Stack>
                            {service &&
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={serviceLoading ? [] : service}
                                    disableCloseOnSelect
                                    onChange={handleSelectChange}
                                    getOptionLabel={(option) => option?.serviceName}
                                    isOptionEqualToValue={(option, value) => {
                                        return option?.id === value?.id
                                    }}
                                    value={selectedServices}
                                    renderOption={(props, option, { selected }) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.serviceName}
                                            </li>
                                        );
                                    }}
                                    style={{ maxWidth: 400, width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Select Services" />
                                    )}
                                />
                            }
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection='row' flexWrap={{ xs: 2, sm: 3, md: 4 }} gap={{ xs: 2, sm: 3, md: 4 }} pt={1} sx={{ overflow: 'auto' }}>
                            <Table stickyHeader aria-label="simple table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Service</TableCell>
                                        <TableCell>QTY</TableCell>
                                        <TableCell>Single Charge</TableCell>
                                        <TableCell>Discount</TableCell>
                                        <TableCell className='job_table'>Total Charge</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listOfTheServices?.map((ele, i) => {
                                        const { id, serviceName, servicePrice, offer } = ele;
                                        const quantity = quantities[id] || 1;
                                        const discPrice = discTotalPrice(servicePrice, offer, quantity);
                                        const totalPrice = calculateTotalPrice(servicePrice, discPrice, quantity);
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>{serviceName}</TableCell>
                                                <TableCell>
                                                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'}
                                                        sx={{ border: '1px solid var(--border)', width: 'fit-content', margin: 'auto', borderRadius: '3px' }}>
                                                        <FaMinus onClick={() => handleDecrement(id)} style={{ cursor: 'pointer', fontSize: '24px', borderRight: '1px solid var(--border)', padding: '3px 6px' }} />
                                                        <Typography style={{ margin: '0 10px' }}>{quantity}</Typography>
                                                        <FaPlus onClick={() => handleIncrement(id)} style={{ cursor: 'pointer', fontSize: '24px', borderLeft: '1px solid var(--border)', padding: '3px 6px' }} />
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>₹ {servicePrice}</TableCell>
                                                <TableCell>₹ {discPrice.toFixed(2)} &nbsp; ( {offer}% )</TableCell>
                                                <TableCell className='job_table'>₹ {totalPrice.toFixed(2)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Stack>
                        <Stack maxWidth='300px' marginLeft='auto' gap='5px' mt={2}>
                            <Stack flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography fontSize='14px'>Total Amount</Typography>
                                <Typography fontSize='14px'>₹ {totalServicePrice - totalOfferPrice}</Typography>
                            </Stack>
                            <Divider />
                            <Stack flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography fontSize='14px'>Extra Charge</Typography>
                                <Typography fontSize='14px'>₹ {jobDetails?.extraCharge === 0 ? '0.00' : jobDetails?.extraCharge}</Typography>
                            </Stack>
                            <Stack flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography fontSize='14px'>Discount</Typography>
                                <Typography fontSize='14px'>₹ {totalOfferPrice ? totalOfferPrice : '0.00'}</Typography>
                            </Stack>
                            <Divider />
                            <Stack flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
                                <Typography fontWeight={600}>Pay Amount</Typography>
                                <Typography fontWeight={600}>₹ {finalPrice ? finalPrice : '0.00'}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Heading smallHead='User Details' />
                        <Stack flexDirection='column' gap={1} mt={2}>
                            <Stack>
                                <Input labelinput='Name' value={jobDetails?.userId?.name} onChange={handleInputChange} disabled />
                            </Stack>
                            <Stack>
                                <Input labelinput='Email' type='email' value={jobDetails?.userId?.email} onChange={handleInputChange} disabled />
                            </Stack>
                            <Stack>
                                <Input labelinput='Mobile' type='number' value={jobDetails?.userId?.mobile} onChange={handleInputChange} disabled />
                            </Stack>
                            <Stack>
                                <Stack mb='2px' flexDirection='row' justifyContent='space-between'>
                                    <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500}>Clients Address</Typography>
                                    <Typography fontSize={{ xs: '12px', sm: '13px' }} sx={{ color: 'var(--primary)', textDecoration: 'underline', cursor: 'pointer' }} fontWeight={500}
                                        onClick={() => setIsModalOpen({ open: true, currentComponent: "addAddress", isEdit: true, addressData: address, setAddress: setAddress, userId: jobDetails?.userId?.id, jobId: jobDetails?.id })}>+ Edit Address</Typography>
                                </Stack>
                                {address === null ?
                                    <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '6px', padding: '8px 15px', minHeight: '80px' }}></Box>
                                    :
                                    <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '6px', padding: '8px 15px' }}>
                                        <Stack>
                                            <Typography fontSize={'14px'}>{`${address?.house}, ${address?.buildingName}, ${address?.landmark}, ${address?.city}, ${address?.address}`}</Typography>
                                            <Typography fontSize={'14px'}><span style={{ color: 'var(--text)' }}>Latitude</span> : {address?.lat}</Typography>
                                            <Typography fontSize={'14px'}><span style={{ color: 'var(--text)' }}>Longitude</span> : {address?.long}</Typography>
                                            <Typography fontSize={'14px'}><span style={{ color: 'var(--text)' }}>Address Type</span> : {address?.addressType}</Typography>
                                        </Stack>
                                    </Box>}
                                {/* {errors.address && <ErrorMessage message={errors.address} />} */}
                            </Stack>
                            <Stack>
                                <Input labelinput='Status' value={getStatusChip(jobDetails?.orderStatus)} disabled />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Heading smallHead='Schedule Details' />
                        <Stack flexDirection='column' gap={1} mt={2}>
                            {(jobDetails?.employeeId && Object?.keys(jobDetails?.employeeId)?.length === 0) ? (
                                <Stack>
                                    <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>
                                        Select Employee
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={employeeLoading ? [] : employeeList}
                                        disableCloseOnSelect
                                        onChange={handleEmployeeListChange}
                                        getOptionLabel={(option) => option?.name}
                                        isOptionEqualToValue={(option, value) => {
                                            return option?.id === value?.id;
                                        }}
                                        value={selectedEmployee}
                                        renderOption={(props, option, { selected }) => {
                                            const { key, ...optionProps } = props;
                                            return (
                                                <li key={key} {...optionProps}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option?.name}
                                                </li>
                                            );
                                        }}
                                        style={{ maxWidth: 800, width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Employee" />
                                        )}
                                    />
                                </Stack>
                            ) : (
                                <Stack>
                                    <Heading smallHead={'Technician Details'} />
                                    <Stack flexDirection={'row'} flexWrap={'wrap'} gap={{ xs: 5 }} pt={1}>
                                        <Stack flexDirection={'row'} gap={1}>
                                            <Typography fontSize={'14px'} color={'var(--text)'}>Name :</Typography>
                                            <Typography fontSize={'14px'} fontWeight={'500'}>
                                                {jobDetails?.employeeId?.name || '-'}
                                            </Typography>
                                        </Stack>
                                        <Stack flexDirection={'row'} gap={1}>
                                            <Typography fontSize={'14px'} color={'var(--text)'}>Mobile :</Typography>
                                            <Typography fontSize={'14px'} fontWeight={'500'}>
                                                {jobDetails?.employeeId?.mobile || '-'}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}
                            <Stack>
                                <Input disabled labelinput='Schedule Date' type='date' value={selectedDate} onChange={handleDateChange} />
                            </Stack>
                            <Stack>
                                <Input disabled labelinput='Schedule Time' value={jobDetails?.orderSlot?.slotTime} onChange={handleDateChange} />
                            </Stack>
                            <Stack>
                                <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>
                                    Note Image
                                </Typography>
                                <UploadImage files={noteImg} setFiles={setNoteImg} />
                            </Stack>
                            <Stack>
                                <Input
                                    labelinput='Note'
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection='row' gap={1}>
                            <Button buttonName='Update' onClick={handleSubmit} />
                            <Button buttonName='Cancel' color='white' onClick={() => navigate('/jobs')} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                modalOpen={isModalOpen}
                onAddressAdded={handleRefetchJobDetails}
                handleClose={() => setIsModalOpen({ open: false, currentComponent: "", head: '', para: '', isEdit: false, addressData: '', setAddress: '', jobId: '', })}
            />
        </>
    )
}

export default EditJob
