import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../helper/Icons';
import { Button, Input, UploadImage, ErrorMessage } from '../../components/Common';
import { useAddNewClientMutation, useUpdateClientMutation } from '../../services/Client';
import { useMessage } from '../../components/Common/Message/MessageContext';

const AddUser = ({ handleClose, modalOpen }) => {
    const { showMessage } = useMessage();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [files, setFiles] = useState([]);
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [addClient] = useAddNewClientMutation();
    const [updateClient] = useUpdateClientMutation();

    const handleSubmit = async () => {
        // Reset error messages
        setNameErrorMessage('');
        setPhoneErrorMessage('');

        // Validation for mandatory fields
        let hasError = false;
        if (!name) {
            setNameErrorMessage('Name is a mandatory field.');
            hasError = true;
        }
        if (!mobile) {
            setPhoneErrorMessage('Mobile is a mandatory field.');
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const formData = new FormData();
        formData.append('image', files);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile', mobile);

        try {
            let response;
            if (modalOpen.isEdit) {
                response = await updateClient({ formData, id: modalOpen.id });
            } else {
                response = await addClient(formData);
            }

            const { status, message } = response?.data || {};

            if (status) {
                showMessage('success', message);
            } else {
                showMessage('error', message);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while processing your request.');
        } finally {
            handleClose();
            setFiles('');
        }
    };

    useEffect(() => {
        if (modalOpen.data) {
            setFiles(modalOpen?.data?.image);
            setName(modalOpen?.data?.name);
            setEmail(modalOpen?.data?.email);
            setMobile(modalOpen?.data?.mobile);
        }
    }, [modalOpen?.data]);

    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                {modalOpen.isEdit ? 'Edit' : 'Add'} User
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container gap={2} width={{ sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>
                            Upload Image
                        </Typography>
                        <UploadImage files={files} setFiles={setFiles} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        <ErrorMessage message={nameErrorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Input labelinput='Mobile' type='number' value={mobile} onChange={(e) => setMobile(e.target.value)} />
                        <ErrorMessage message={phoneErrorMessage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
                <Button buttonName={modalOpen.isEdit ? 'Update' : 'Save'} size='small' onClick={handleSubmit} />
            </DialogActions>
        </>
    );
};

export default AddUser;
