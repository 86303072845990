import React from 'react'
import TextField from "@mui/material/TextField";
import { Box, IconButton, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '../../helper/Icons'


const CommonInput = (props) => {
  const { type, placeholder, iconinput, labelinput, value, disabled, passwordinput, style, className } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      {labelinput && <Typography fontSize={{ xs: '12px', sm: '13px' }} fontWeight={500} mb={'2px'}>{labelinput}</Typography>}
      <Box position={'relative'}>
        <TextField
          fullWidth
          hiddenLabel
          name={props.name}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          type={type ? type : passwordinput ? showPassword ? 'text' : 'password' : 'text'}
          color="primary"
          sx={style}
          className={className}
          {...props}
        />
        {(passwordinput || iconinput) &&
          passwordinput ?
          <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: { xs: '7px', sm: '7px' }, top: { xs: '4px', sm: '4px' } }}>
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff style={{ color: 'var(--black)', fontSize: '20px', }} />
                : <Visibility style={{ color: 'var(--black)', fontSize: '20px', }} />}
            </IconButton>
          </InputAdornment>
          : iconinput &&
          <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: { xs: '7px', sm: '7px' }, top: { xs: '4px', sm: '4px' } }}>
            <IconButton> {iconinput} </IconButton>
          </InputAdornment>
        }
      </Box>
    </>
  )
}

export default CommonInput