import React, { useState, useCallback } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function FileUpload({ files, setFiles, setErrorMessage }) {
    const [dragging, setDragging] = useState(false);

    const checkImageResolution = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                if (img.width === 3100 && img.height === 1500) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };

            img.onerror = () => {
                reject(new Error('Failed to load image'));
            };
        });
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.size <= 1 * 1024 * 1024) { // 1 MB in bytes
                const isValidResolution = await checkImageResolution(selectedFile);
                if (isValidResolution) {
                    setFiles(selectedFile);
                    setErrorMessage('');
                } else {
                    setErrorMessage('Image resolution must be 3100×1500');
                }
            } else {
                setErrorMessage('File size exceeds 1 MB');
            }
        }
    };

    const handleDragEnter = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    }, []);

    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const handleDrop = useCallback(async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        if (droppedFiles.length) {
            const selectedFile = droppedFiles[0];
            if (selectedFile.size <= 1 * 1024 * 1024) { // 1 MB in bytes
                const isValidResolution = await checkImageResolution(selectedFile);
                if (isValidResolution) {
                    setFiles(selectedFile);
                    setErrorMessage('');
                } else {
                    setErrorMessage('Image resolution must be 3100×1500');
                }
            } else {
                setErrorMessage('File size exceeds 1 MB');
            }
        }
    }, [setFiles, setErrorMessage]);

    const handleRemoveFile = () => {
        setFiles(null);
    };

    const handleUploadClick = () => {
        console.log('File to upload:', files);
        // Upload logic goes here
    };

    return (
        <Box>
            <label htmlFor="raised-button-file" onClick={handleUploadClick} style={{ width: '100%' }}>
                <Box
                    sx={{
                        padding: '0 10px',
                        width: '100%',
                        height: '70px',
                        border: '1px dashed #c4c4c4',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: dragging ? '#e0e0e0' : '#f8f8f8',
                        fontSize: { xs: '13px', sm: '14px' },
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    Resolution: 3100×1500 (Max. Allowed file size is 1 MB)
                    <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" type="file" onChange={handleFileChange} />
                </Box>
            </label>

            {/* Thumbnail */}
            {files && (
                <Stack gap={'10px'} flexDirection={'row'} flexWrap={'wrap'} style={{ marginTop: '10px' }}>
                    <Box sx={{ width: '70px', height: '70px', position: 'relative', border: '1px solid #e3e3e3', borderRadius: '10px' }}>
                        <IconButton
                            style={{ position: 'absolute', right: 0, top: 0 }}
                            onClick={handleRemoveFile}
                        >
                            <DeleteIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                        <img
                            src={typeof files === "string" && files ? files : URL.createObjectURL(files)}
                            alt='preview'
                            style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
                        />
                    </Box>
                </Stack>
            )}
        </Box>
    );
}

export default FileUpload;
