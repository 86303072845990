import React, { useState, useCallback } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function MultipleUpload({ files, setFiles, setErrorMessage, setDeletedFilesIds }) {
    const [dragging, setDragging] = useState(false);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
        if (newFiles.length) {
            setErrorMessage('');
        }
    };

    const handleDragEnter = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    }, []);

    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        if (droppedFiles.length) {
            setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
        }
    }, []);

    const handleRemoveFile = (id) => {
        setFiles(prevFiles => prevFiles.filter((file, i) => file.id !== id));
        setDeletedFilesIds(prevFilesIds => [...prevFilesIds, id])
    };

    const handleUploadClick = () => {
        console.log('Files to upload:', files);
        // Upload logic goes here
    };

    return (
        <Box>
            <label htmlFor="raised-button-file" onClick={handleUploadClick} style={{ width: '100%' }}>
                <Box
                    sx={{
                        width: '100%', height: '70px',
                        padding: '0 10px',
                        border: '1px dashed #c4c4c4', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: dragging ? '#e0e0e0' : '#f8f8f8', fontSize: { xs: '13px', sm: '14px' }, fontWeight: '500', cursor: 'pointer'
                    }}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    Drop your files here or click to browse
                    <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" type="file" multiple onChange={handleFileChange} />
                </Box>
            </label>

            {/* Thumbnails */}
            {files?.length > 0 && (
                <Stack gap={'10px'} flexDirection={'row'} flexWrap={'wrap'} style={{ marginTop: '10px' }}>
                    {files.map((file, index) => (
                        <Box key={index} sx={{ width: '70px', height: '70px', position: 'relative', border: '1px solid #e3e3e3', borderRadius: '10px' }}>
                            <IconButton
                                style={{ position: 'absolute', right: 0, top: 0 }}
                                onClick={() => handleRemoveFile(file.id)}
                            >
                                <DeleteIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                            <img
                                src={typeof file.bannerImage === "string" && file ? file.bannerImage : URL.createObjectURL(file)}
                                alt='preview'
                                style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
                            />
                        </Box>
                    ))}
                </Stack>
            )}
        </Box>
    );
}

export default MultipleUpload;
