import React, { useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../../helper/Icons';
import { Button } from '..';
import { useMessage } from '../Message/MessageContext';

const ActionModal = ({ handleClose, modalOpen }) => {
  const [loading, setLoading] = useState(false);
  const { showMessage } = useMessage();

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await modalOpen?.delete(modalOpen.id);
      if (response?.data?.status) {
        showMessage('success', response.data.message || 'Banner deleted successfully');
      } else {
        showMessage('error', response?.data?.message || 'Failed to delete banner');
      }
    } catch (error) {
      showMessage('error', 'An error occurred while deleting the banner');
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.head}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: '400px' }}>
          <Typography fontSize={'18px'}>{modalOpen.para}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName={'No'} size='small' color='white' onClick={handleClose} />
        <Button buttonName={'Yes'} size='small' onClick={handleDelete} disabled={loading} />
      </DialogActions>
    </>
  );
};

export default ActionModal;
